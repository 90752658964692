import React from "react";
import { useTranslation } from "react-i18next";
import { OpenAPI } from "simplydo/interfaces";

const PinIdeaHelper = ({
  challenge,
}: {
  challenge: {
    ideaVisibilityLimits?: OpenAPI.Schemas["Challenge"]["ideaVisibilityLimits"];
  };
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <p>
        Pinning {t("generic.ideaWithArticle")} places it at the top of the {t("generic.idea")} list for anyone viewing
        this {t("generic.challenge")}'s {t("generic.ideas")}.
      </p>
      {challenge.ideaVisibilityLimits?.length ? (
        <p>
          In this {t("generic.challenge")}, {t("generic.ideas")} are only shown to non-{t("generic.challenge")}
          -administrators if they are:{" "}
          <b>{challenge.ideaVisibilityLimits.map((iv) => iv.charAt(0).toUpperCase() + iv.slice(1)).join(", ")}</b>
        </p>
      ) : null}
    </div>
  );
};

export default PinIdeaHelper;
