import React, { useEffect, Dispatch, SetStateAction, useMemo } from "react";
import { Label, Icon, Popup, SemanticCOLORS, SemanticICONS, Placeholder } from "semantic-ui-react";
import moment from "moment";
import util from "utils/utils";
import styled from "styled-components";
import { useReward } from "react-rewards";
import { OpenAPI } from "simplydo/interfaces";

import Emoji from "components/lib/Emoji/Emoji";
import { UserChip, IdeaBusinessProfileChip } from "components/lib/Chips";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { IIdeaCard } from "./Board";
import { Checkbox } from "components/lib/UI";
import { useTranslation } from "react-i18next";
import { formatImpact, impactMultipliers } from "../Ideas/ImpactStatements/statementUtils";

const StyledCard = styled.div<{
  $highlightCard?: boolean;
  $isAssigned?: boolean;
  $isSelecting?: boolean;
  $isSelected?: boolean;
}>`
  position: relative;

  box-sizing: border-box;
  overflow: visible !important;

  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 1px 0px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px 0px;
  border-radius: 5px;
  border: ${({ $highlightCard }) => ($highlightCard ? "2px solid red" : null)};
  padding: 8px 14px;

  background: white;
  ${({ $isSelecting }) =>
    !$isSelecting
      ? `
    &:hover {
      background-color: #f4f5f7;
    }
  `
      : `
    cursor: default;
  `}

  transition: transform 0.08s ease-in-out;

  ${({ $isSelecting, $isSelected }) =>
    $isSelecting
      ? `
    &:hover {
      .icon-checkbox {
        font-size: 2em !important;
      }
    }
    &:not(:hover) {
      ${
        !$isSelected &&
        `
        >*:not(.selection-box) {
          opacity: 0.6;
        }
      `
      }
    }
    >*:not(.selection-box) {
      cursor: pointer;
    }
  `
      : null}
  .selection-box {
    opacity: 1;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 200;
  }
  header {
    min-height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: flex-start;
  > .header-info {
    display: flex;
    gap: 3px;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    > h4,
    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      -moz-line-clamp: 3;
      line-clamp: 3;
      margin: 0 0 5px 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 8px;
`;

const MetaContainer = styled.div`
  display: flex;
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
  margin-top: 4px;
  padding-top: 4px;
  gap: 0;
  justify-content: space-between;
  align-items: flex-end;

  .emoji {
    width: 11px;
  }

  .emoji-mart-emoji {
    line-height: 1em;
  }

  .stamps {
    display: flex;
    flex-direction: row;
  }

  .more-stamps {
    display: block;
    margin-left: 10px;
  }
`;

const AssigneeImage = styled.div<{ $isCurrentUser?: boolean; $user?: OpenAPI.Schemas["User"]; $isOtherChip?: boolean }>`
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: -7px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
  ${({ $isCurrentUser, theme }) =>
    $isCurrentUser
      ? `
    border: 3px solid ${theme.accent};
    width: 38px;
    height: 38px;
  `
      : ""}

  ${({ $user, $isOtherChip }) =>
    !$isOtherChip && ($user?.profile?.avatar || !$user?.profile?.firstName || !$user?.profile?.lastName)
      ? `
    background-size: cover;
    background-position: center center;
    background-image: url(${util.mixinCssUrlFallback(util.avatarUrl($user), util.avatarUrl())});
  `
      : `
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F3F3F3;
    > span {
      display: block;
      color: #666666;
      font-weight: bold;
      text-align: center;
    }
  `}
`;

const StyledStatusLabel = styled.div<{ $backgroundColor: string }>`
  display: flex;
  align-items: center;
  gap: 5px;
  > div {
    width: 8px;
    height: 8px;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    border-radius: 8px;
  }
  > span {
    font-size: 11px;
  }
`;

const AssigneeImageContainer = styled.div<{ $count?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: ${({ $count = 0 }) => 7 + Math.min($count, 6) * 3}px;

  ${AssigneeImage} {
    margin-right: -${({ $count = 0 }) => 7 + Math.min($count, 6) * 3}px;
  }
`;

const getDeadlineStatus: (deadline: string | Date) => {
  color: SemanticCOLORS;
  icon: SemanticICONS;
  text: string;
  isOverdue?: boolean;
} = (deadline) => {
  if (typeof deadline === "string") {
    deadline = new Date(deadline);
  }
  if (!deadline) return;
  if (deadline > moment().add(3, "days").toDate())
    return { color: "green", icon: "calendar", text: "On track to deadline" };
  if (deadline < new Date()) {
    return {
      color: "red",
      icon: "calendar",
      text: "Deadline overdue",
      isOverdue: true,
    };
  }
  if (deadline > new Date()) return { color: "orange", icon: "calendar", text: "Approaching deadline" };
};

const getReviewDateStatus: (reviewDate: string | Date) => {
  color: SemanticCOLORS;
  icon: SemanticICONS;
  text: string;
  isOverdue?: boolean;
} = (reviewDate) => {
  if (typeof reviewDate === "string") {
    reviewDate = new Date(reviewDate);
  }
  if (!reviewDate) return;
  if (reviewDate > moment().add(3, "days").toDate())
    return { color: "green", icon: "calendar", text: "On track to review date" };
  if (reviewDate < new Date()) {
    return {
      color: "red",
      icon: "calendar",
      text: "Review date overdue",
      isOverdue: true,
    };
  }
  if (reviewDate > new Date()) return { color: "orange", icon: "calendar", text: "Approaching review date" };
};

const getWarning = (board) => {
  const updatedAt = board?.updatedAt && moment(board.updatedAt);
  if (!updatedAt) {
    return { updatedAt: undefined, status: "green" };
  }

  const lowerLimit = moment().subtract(1, "months");
  const upperLimit = moment().subtract(3, "months");
  let status = "green";
  if (updatedAt.isAfter(lowerLimit)) {
    status = "green";
  } else if (updatedAt.isBefore(lowerLimit) && updatedAt.isAfter(upperLimit)) {
    status = "orange";
  } else {
    status = "red";
  }
  return { updatedAt, status };
};

type ICustomCard = {
  card: IIdeaCard;
  handleProps: DraggableProvidedDragHandleProps;
  user: OpenAPI.GET<"/users/me">["response"];
  highlightCard: string;
  rewardIdeaId: string;
  setRewardIdeaId: Dispatch<SetStateAction<string>>;
  onClick: (ideaId: string) => void;
  getBoard: (idea: OpenAPI.Schemas["Idea"]) => OpenAPI.Schemas["ProjectBoard"];
  isSelecting?: boolean;
  isSelected?: boolean;
  onSelect?: (ideaId: string) => void;
  loading?: boolean;
  basicLoading?: boolean;
};

const CustomCard = (props: ICustomCard) => {
  const { idea, laneMetaType } = props.card;
  const {
    user,
    handleProps,
    onClick,
    highlightCard,
    getBoard,
    rewardIdeaId,
    setRewardIdeaId,
    isSelecting,
    onSelect,
    isSelected,
    loading,
    basicLoading,
  } = props;
  const { t } = useTranslation();

  const { reward } = useReward(`implementReward${idea._id}`, "confetti", {
    lifetime: 70,
    startVelocity: 30,
    onAnimationComplete: () => {
      setRewardIdeaId(null);
    },
  });

  useEffect(() => {
    if (rewardIdeaId === idea?._id) {
      reward();
    }
  }, [idea?._id, reward, rewardIdeaId]);

  const cardProps = useMemo(
    () =>
      idea && idea._id
        ? isSelecting
          ? {
              $isSelecting: true,
              $isSelected: isSelected,
              onClick: () => onSelect(idea._id),
            }
          : {
              onClick: () => onClick(idea._id),
              $highlightCard: highlightCard === idea._id,
              ...handleProps,
            }
        : null,
    [handleProps, highlightCard, idea, isSelected, isSelecting, onClick, onSelect],
  );

  const usesImpactStatementSystem = user?.ownerOrganisation?.enabledFeatures?.includes("impactStatements");

  if (!idea || !idea?._id) return null;
  const board = getBoard(idea);
  const deadlineStatus = getDeadlineStatus(board?.deadline);
  const reviewDateStatus = getReviewDateStatus(board?.reviewDate);
  const { updatedAt, status } = getWarning(board);
  const submittedAssessments = idea.assessments?.filter((a) => a.isSubmitted).length;
  const cardLaneHasMetaType =
    laneMetaType && (laneMetaType === "notProgressing" || laneMetaType === "completed" || laneMetaType === "resolved");

  let submittedColor = "green";
  if (submittedAssessments === 0) {
    submittedColor = "red";
  } else if (submittedAssessments < idea.assessments?.length) {
    submittedColor = "orange";
  }

  const annualImpactValue = (impact) => {
    if (usesImpactStatementSystem) {
      return formatImpact(impact.ownerImpact, impact, {
        annually: true,
      });
    }

    if (typeof impact?.value === "string") {
      return impact?.value;
    }
    const annual = (impactMultipliers[impact?.frequency] ?? 1) * impact?.value;
    if (impact?.ownerImpact?.type === "time") {
      return util.convertToDayOrWeek(annual);
    }
    if (impact?.ownerImpact?.type === "money") {
      return `£${annual}`;
    }
    return annual;
  };

  const generateImpactText = (impact) => {
    const effectMap = {
      cost: "cost",
      saving: "save",
    };

    const effectText = effectMap[impact?.ownerImpact?.effect] ?? "have an impact of";
    let text = `This ${t("generic.idea")} will ${effectText} `;
    if (usesImpactStatementSystem) {
      text += formatImpact(impact.ownerImpact, impact);
    } else {
      if (impact?.ownerImpact?.type === "time") {
        text += `${impact?.value} hours`;
      } else if (impact?.ownerImpact?.type === "money") {
        text += `£${impact?.value}`;
      } else {
        text += `"${impact?.value}"`;
      }
    }

    if (!impact?.frequency || impact?.frequency === "one-off") {
      text += " once";
    } else {
      text += ` ${impact?.frequency}`;
      if (impact?.frequency !== "annually") {
        text += ` (${annualImpactValue(impact)} annually)`;
      }
    }
    text += ".";

    return (
      <>
        <p>
          <strong>{impact?.ownerImpact?.name}</strong>
        </p>
        <p>{text}</p>
        {impact?.justification ? <i>{impact?.justification}</i> : null}
      </>
    );
  };

  return (
    <StyledCard id={`implementReward${idea._id}`} {...cardProps}>
      {isSelecting ? (
        <Checkbox
          className="selection-box"
          isSelected={isSelected}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(idea._id);
          }}
        />
      ) : null}
      <header>
        <CardHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              alignSelf: "flex-start",
            }}
          >
            {idea?.totalImpacts
              ?.filter((x) => x?.value)
              ?.map((i) => (
                <Label
                  key={i.impactId}
                  color={
                    i.ownerImpact?.effect === "cost" ? "orange" : i.ownerImpact?.effect === "saving" ? "teal" : "grey"
                  }
                  size="tiny"
                  ribbon
                >
                  <Popup
                    position="right center"
                    content={generateImpactText(i)}
                    trigger={
                      <div>
                        <Icon name={i.ownerImpact?.icon} />
                        {i.ownerImpact?.effect === "cost" ? "-" : ""}
                        {annualImpactValue(i)}
                      </div>
                    }
                  />
                </Label>
              ))}
          </div>
          <div className="header-info">
            {idea.children ? (
              <Popup
                trigger={<Icon name="sitemap" />}
                content={`This ${t("generic.idea")} is a merged ${t("generic.idea")}.`}
              />
            ) : null}

            <Popup trigger={<h4 style={{ margin: 0 }}>{idea.name}</h4>} content={idea.name} />
          </div>
        </CardHeader>
      </header>
      <CardFooter onDragStart={(e) => e.preventDefault()}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!idea.isSubmitted ? (
            <StyledStatusLabel $backgroundColor="orange">
              <div />
              <span>Draft {t("generic.idea")}</span>
            </StyledStatusLabel>
          ) : null}
          {updatedAt ? (
            <Popup
              content={`This ${t("generic.idea")} was last updated on ${updatedAt.format("DD/MM/YYYY")} (${updatedAt.fromNow()})`}
              position="right center"
              trigger={
                <StyledStatusLabel $backgroundColor={status}>
                  <div />
                  <span>Updated: {moment(updatedAt).format("Do MMM 'YY")}</span>
                </StyledStatusLabel>
              }
            />
          ) : null}
          {deadlineStatus && !cardLaneHasMetaType ? (
            <Popup
              content={`${deadlineStatus.text} : ${moment(board.deadline).format("DD/MM/YYYY")} (${moment(board.deadline).fromNow()})`}
              position="right center"
              trigger={
                <StyledStatusLabel $backgroundColor={deadlineStatus.color}>
                  <div />
                  <span>Deadline: {moment(board.deadline).format("Do MMM 'YY")}</span>
                </StyledStatusLabel>
              }
            />
          ) : null}
          {reviewDateStatus && !cardLaneHasMetaType ? (
            <Popup
              content={`${reviewDateStatus.text} : ${moment(board.reviewDate).format("DD/MM/YYYY")} (${moment(board.reviewDate).fromNow()})`}
              position="right center"
              trigger={
                <StyledStatusLabel $backgroundColor={reviewDateStatus.color}>
                  <div />
                  <span>Review date: {moment(board.reviewDate).format("Do MMM 'YY")}</span>
                </StyledStatusLabel>
              }
            />
          ) : null}
          {idea.assessments?.length ? (
            <Popup
              content={`${submittedAssessments} of a total ${idea.assessments.length} assessments have been submitted. Their current combined score is ${Math.round(idea.assessmentSubmittedScore || 0)}%.`}
              position="right center"
              trigger={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    backgroundColor: "#6435c9",
                    marginLeft: -4.5,
                    padding: "2px 5px",
                    color: "#fff",
                    borderRadius: 4,
                  }}
                >
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      backgroundColor: submittedColor,
                      borderRadius: 8,
                    }}
                  />
                  <span style={{ fontSize: 11 }}>
                    {loading && !basicLoading
                      ? "Loading assessments..."
                      : `Assessed: ${Math.round(idea.assessmentSubmittedScore || 0)}% (${submittedAssessments || 0}/${idea.assessments.length || 0})`}
                  </span>
                </div>
              }
            />
          ) : null}
        </div>
        <AssigneeImageContainer $count={board?.assigneeUsers?.length}>
          {board?.assigneeUsers?.length > 0 ? (
            <>
              {board.assigneeUsers.slice(0, 6).map((assignee) => (
                <Popup
                  key={assignee._id}
                  content={
                    assignee._id === user._id
                      ? `You are assigned to this ${t("generic.idea")}.`
                      : `${assignee.profile.fullName} is assigned to this ${t("generic.idea")}.`
                  }
                  trigger={
                    <AssigneeImage key={assignee._id} $user={assignee} $isCurrentUser={assignee._id === user._id}>
                      {!assignee?.profile?.avatar && assignee.profile.firstName && assignee.profile.lastName && (
                        <span>
                          {assignee.profile.firstName[0].toUpperCase()}
                          {assignee.profile.lastName[0].toUpperCase()}
                        </span>
                      )}
                    </AssigneeImage>
                  }
                />
              ))}
              {board.assigneeUsers?.length > 6 ? (
                <Popup
                  content={board.assigneeUsers.slice(6).map((a) => (
                    <p key={a._id}>{a.profile.fullName}</p>
                  ))}
                  trigger={
                    <AssigneeImage $isOtherChip={true}>
                      <span>{`+${board.assigneeUsers?.length - 6}`}</span>
                    </AssigneeImage>
                  }
                />
              ) : null}
            </>
          ) : null}
        </AssigneeImageContainer>
      </CardFooter>
      {idea.lockedOwnerIdeaBusinessProfile || idea.owner || idea.stamps?.length || (!basicLoading && loading) ? (
        <MetaContainer>
          {idea.lockedOwnerIdeaBusinessProfile || idea.owner || (!basicLoading && loading) ? (
            <div style={{ display: "flex", flexDirection: "column", marginRight: 5, overflow: "auto" }}>
              <span
                style={{
                  fontSize: 12,
                  color: "#7a7a7a",
                }}
              >
                Created by:
              </span>
              {idea.lockedOwnerIdeaBusinessProfile ? (
                <IdeaBusinessProfileChip compact ideaBusinessProfile={idea.lockedOwnerIdeaBusinessProfile} />
              ) : (
                <>
                  {loading ? (
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "flex-start" }}>
                      <Placeholder style={{ height: 30, width: 30, borderRadius: 5 }}>
                        <Placeholder.Image square />
                      </Placeholder>
                    </div>
                  ) : null}
                  {idea.owner ? (
                    <UserChip
                      // @ts-ignore
                      user={idea.owner}
                      compact
                    />
                  ) : null}
                </>
              )}
            </div>
          ) : (
            <div />
          )}
          {idea.stamps?.length ? (
            <div className="stamps">
              {idea.stamps.slice(0, 5).map((stamp) => (
                <div key={stamp} className="emoji">
                  <Emoji emoji={{ id: stamp }} size={18} selected={undefined} onClick={undefined} />
                </div>
              ))}
              {idea.stamps?.length > 5 ? (
                <Popup
                  trigger={<span className="more-stamps">+{idea.stamps?.length - 5}</span>}
                  content={
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                      {idea.stamps.slice(5).map((stamp) => (
                        <div key={stamp} className="emoji">
                          <Emoji emoji={{ id: stamp }} size={18} selected={undefined} onClick={undefined} />
                        </div>
                      ))}
                    </div>
                  }
                />
              ) : null}
            </div>
          ) : null}
        </MetaContainer>
      ) : null}
    </StyledCard>
  );
};

export default CustomCard;
