import React, { useState, useCallback, ReactElement, Fragment, cloneElement, useMemo } from "react";
import { Modal, Button, List, Header, Message, Form, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import { OpenAPI } from "simplydo/interfaces";
import actions from "actions";
import util from "utils/utils";
import moment from "moment";
import { DisabledExplanationPopup } from "./UI";
import { useTranslation } from "react-i18next";

interface IChallengeStageChanger {
  challengeId: string;
  challenge?: OpenAPI.Schemas["Challenge"];
  user: OpenAPI.GET<"/users/me">["response"];
  trigger: ReactElement;
  history: History;
  match: {
    params: {
      id: string;
    };
  };
  updateStage: (newStage: string, openWithComms?: boolean) => void;
}

const ChallengeStageChanger = ({ challenge, trigger, updateStage, user }: IChallengeStageChanger) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const currentStage: string | undefined = challenge?.stage;
  const deadline = challenge?.deadline;
  const { t } = useTranslation();

  const [sendCommunications, setSendCommunications] = useState(false);

  const deadlineInPast = useMemo(() => deadline && moment(deadline) < moment(), [deadline]);

  const handleUpdateStage = useCallback(() => {
    const newStage = !currentStage || currentStage === "closed" ? "published" : "closed";
    if (newStage === "published") {
      setConfirmationModalOpen(true);
    } else {
      updateStage("closed");
    }
  }, [currentStage, updateStage]);

  const noAudience = useMemo(
    () => !challenge?.audienceSize || challenge?.audienceSize === 0,
    [challenge?.audienceSize],
  );

  const noIdeaFormat = useMemo(
    () => !challenge?.ideaTemplateData?.body || challenge?.ideaTemplateData.body?.length === 0,
    [challenge?.ideaTemplateData?.body],
  );

  return (
    <Fragment>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      >
        <Modal.Header>Open {t("generic.challenge")} to audience</Modal.Header>
        <Modal.Content>
          <p>
            You are about to open the {t("generic.challenge")} "{challenge?.name}" to its audience. Please read and
            review the following before proceeding:
          </p>
          {challenge?.audienceSize && challenge?.audienceSize > 0 ? (
            <Header as="h5">
              There are {util.pluralise(challenge.audienceSize, "user", "users")} in the audience of this{" "}
              {t("generic.challenge")}
            </Header>
          ) : null}
          <List bulleted>
            <List.Item>
              Anyone in the {t("generic.challenge")} audience will be able to make {t("generic.ideas")} for this{" "}
              {t("generic.challenge")}.
            </List.Item>
            <List.Item>
              All information that is part of the {t("generic.challenge")} details will be visible to people viewing the{" "}
              {t("generic.challenge")}. This includes any text, images or files in the summary, description and
              attachments. Please verify no sensitive information will be revealed.
            </List.Item>
          </List>
          {util.hasPermission(user, "org.viewDashboard", challenge?.organisation) ? (
            <>
              <Header as="h5">User notifications</Header>
              <Form>
                <Form.Field>
                  <Checkbox
                    radio
                    label={
                      <label htmlFor="without">
                        <span>
                          <b>Open the {t("generic.challenge")} to the audience, but do not send notification emails</b>
                          <br />
                          Users will be able to view and access the {t("generic.challenge")}, but they will not receive
                          any notification prompting them to view the {t("generic.challenge")}.
                        </span>
                      </label>
                    }
                    name="without"
                    value="without"
                    checked={!sendCommunications}
                    onChange={() => setSendCommunications(false)}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    label={
                      <label htmlFor="with">
                        <span>
                          <b>Open the {t("generic.challenge")} to the audience and send notification emails</b>
                          <br />
                          Emails and notifications will be sent to users with tags that match this{" "}
                          {t("generic.challenge")}. The email will prompt them to view and participate in the{" "}
                          {t("generic.challenge")}.
                        </span>
                      </label>
                    }
                    name="with"
                    value="with"
                    checked={sendCommunications}
                    onChange={() => setSendCommunications(true)}
                  />
                </Form.Field>
              </Form>
            </>
          ) : null}
          {noIdeaFormat || noAudience ? (
            <Message warning>
              <Message.Header style={{ marginBottom: 5 }}>Missing {t("generic.challenge")} information</Message.Header>
              <Message.Content>
                There seems to be some missing information in the {t("generic.challenge")} settings. You can open the{" "}
                {t("generic.challenge")} now, but please be aware that this may cause issues for users. Make sure to
                review this information before opening the {t("generic.challenge")}. The following issues have been
                identified:
                <List bulleted style={{ marginTop: 10 }}>
                  {noAudience ? (
                    <List.Item>
                      <b>There is currently no audience set up for this {t("generic.challenge")}.</b>
                    </List.Item>
                  ) : null}
                  {noIdeaFormat ? (
                    <List.Item>
                      <b>
                        There is currently no {t("generic.idea")} format selected for this {t("generic.challenge")}.
                      </b>
                    </List.Item>
                  ) : null}
                </List>
                <p>Please revisit the settings to finish setting up this {t("generic.challenge")}.</p>
              </Message.Content>
            </Message>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Cancel"
            onClick={() => {
              setSendCommunications(false);
              setConfirmationModalOpen(false);
            }}
          />
          <Button
            primary
            content={`Open ${t("generic.challenge")}`}
            onClick={() => {
              setConfirmationModalOpen(false);
              setSendCommunications(false);
              updateStage("published", sendCommunications);
            }}
          />
        </Modal.Actions>
      </Modal>
      <DisabledExplanationPopup
        content={`You cannot open this ${t("generic.challenge")} as it currently has a deadline in the past, and therefore will close as soon as it is opened. Please update the deadline, or remove it, in the ${t("generic.challenge")} settings`}
        disabled={challenge?.stage !== "published" && deadlineInPast}
        on="hover"
        hoverable
      >
        <div>{cloneElement(trigger, { onClick: () => handleUpdateStage() })}</div>
      </DisabledExplanationPopup>
    </Fragment>
  );
};

const mapStateToProps = (
  state: { user: OpenAPI.GET<"/users/me">["response"]; challenges: { challenges: OpenAPI.Schemas["Challenge"][] } },
  props: IChallengeStageChanger,
) => {
  const challenge = state.challenges.challenges.find((c) => c._id === props.challengeId);
  return { user: state.user, challenge };
};
const mapDispatchToProps = (dispatch) => ({
  update: (id, challenge) => dispatch(actions.challenges.update(id, challenge)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeStageChanger);
