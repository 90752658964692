import React, { useCallback, useState } from "react";
import {
  Divider,
  Label,
  Icon,
  Form,
  Popup,
  Button,
  Dropdown,
  Checkbox,
  Segment,
  Image,
  Message,
} from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";

import constants from "utils/constants";
import { countries, regions } from "utils/countries";

import util from "utils/utils";
import toast from "react-hot-toast";
import styled from "styled-components";
import api, { asMutation, asQuery, Response } from "api";
import { businessSizeOptions, businessStatusOptions, businessTypeOptions } from "./Overview";
import { ImageChooser } from "../../Choosers";
import { TooltipButton } from "../../UI";
import TagModalChooser from "../../Choosers/Tags/TagModalChooser";
import { useAppSelector } from "store";
import { useMutation, useQuery } from "@tanstack/react-query";

const UploadImageSegment = styled(Segment)`
  min-width: 100%;
  max-width: 200px;
  margin-bottom: 16px !important;

  .ui.image {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const AccreditationsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  .ui.label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

type EditOverviewProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  setUnsaved?: (unsaved: boolean) => void;
};

export const EditOverview = ({ businessProfile, setUnsaved }: EditOverviewProps) => {
  const [addingCustomAccreditation, setAddingCustomAccreditation] = useState(false);
  const [generatingDescription, setGeneratingDescription] = useState(false);
  const [customAccreditation, setCustomAccreditation] = useState("");
  const user = useAppSelector((state) => state.user);

  const {
    name,
    description,
    address = {},
    websiteUrl,
    websiteUrlSkipped,
    size,
    type,
    status,
    accreditations = [],
    foundingYear,
    creditsafeRegNo,
    creditsafeRegNoSkipped,
    vatNo,
    vatNoSkipped,
    ownerTags,
  } = businessProfile || {};
  const { country, city, streetAddress, postcode, region } = address;
  const tagsQuery = useQuery({
    queryKey: ["get", "tags", "organisation", user.ownerOrganisation._id],
    queryFn: asQuery(api.tags.getAllForEntity, { params: ["organisation", user.ownerOrganisation._id, false] }),
  });

  const addTagMutation = useMutation({
    mutationKey: ["post", "tags", "ideaBusinessProfiles", "tag"],
    mutationFn: asMutation(api.tags.tagSingle),
    onSuccess: (tagData: Response<typeof api.tags.tagSingle>) => {
      api.queryClient.setQueryData(
        ["get", "users", "profiles", businessProfile._id],
        (oldData: OpenAPI.GET<"/users/profiles/{business_profile_id}">["response"]) => ({
          ideaBusinessProfile: {
            ...oldData.ideaBusinessProfile,
            tags: [...(oldData.ideaBusinessProfile?.tags ?? []), tagData._id],
            ownerTags: [...(oldData.ideaBusinessProfile?.ownerTags ?? []), tagData],
          },
        }),
      );
    },
  });

  const removeTagMutation = useMutation({
    mutationKey: ["delete", "tags", "ideaBusinessProfiles", "tag"],
    mutationFn: asMutation(api.tags.untagSingle),
    onSuccess: (tagData: Response<typeof api.tags.untagSingle>) => {
      api.queryClient.setQueryData(
        ["get", "users", "profiles", businessProfile._id],
        (oldData: OpenAPI.GET<"/users/profiles/{business_profile_id}">["response"]) => ({
          ideaBusinessProfile: {
            ...oldData.ideaBusinessProfile,
            tags: oldData.ideaBusinessProfile?.tags?.filter((t) => t !== tagData._id),
            ownerTags: oldData.ideaBusinessProfile?.ownerTags?.filter((t) => t._id !== tagData._id),
          },
        }),
      );
    },
  });

  const updateProfile = useCallback(
    (data) => {
      api.queryClient.setQueryData<OpenAPI.GET<"/users/profiles/{business_profile_id}">["response"]>(
        ["get", "users", "profiles", businessProfile?._id],
        (oldData) => ({
          ideaBusinessProfile: {
            ...oldData.ideaBusinessProfile,
            ...data,
          },
        }),
      );

      setUnsaved?.(true);
    },
    [setUnsaved, businessProfile?._id],
  );

  const generateDescription = useCallback(() => {
    setGeneratingDescription(true);
    api.users.generateBusinessDescription(
      {
        companyName: name,
        websiteUrl: websiteUrl,
      },
      ({ success, error, description }) => {
        if (!success) {
          toast.error(error);
          setGeneratingDescription(false);
        } else {
          updateProfile({ description });
          setGeneratingDescription(false);
        }
      },
      (err) => {
        toast.error(err.message);
        setGeneratingDescription(false);
      },
    );
  }, [websiteUrl, name, updateProfile]);

  const currentYear = new Date().getFullYear();

  return (
    <>
      <Form>
        <div style={{ display: "flex", gap: 24 }}>
          <ImageChooser
            forType="ideaBusinessProfile"
            forId={businessProfile._id}
            trigger={
              <div>
                <UploadImageSegment>
                  {businessProfile?.imageUrl ? (
                    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                      <div
                        style={{
                          display: "flex",
                          alignSelf: "flex-start",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 4,
                          overflow: "hidden",
                          maxHeight: 150,
                          width: 150,
                        }}
                      >
                        <Image
                          style={{ objectFit: "contain" }}
                          src={businessProfile?.imageUrl}
                          alt={businessProfile?.name}
                        />
                      </div>
                      <Button
                        size="mini"
                        basic
                        fluid
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          util
                            .confirm("Remove logo", "Are you sure you want to remove the logo?")
                            .then(() => {
                              updateProfile({ imageUrl: null, image: null });
                            })
                            .catch(() => {});
                        }}
                      >
                        <Icon name="trash" />
                        Remove
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Message info>
                        <Message.Header>Upload a logo</Message.Header>
                        <p>We recommend a square image with a size of 500x500 pixels in either PNG or JPG format.</p>
                      </Message>
                      <TooltipButton fluid icon="upload" wrapperStyle={{ display: "flex" }} content="Choose image" />
                    </>
                  )}
                </UploadImageSegment>
              </div>
            }
            onComplete={(responseFilename, fn, downloadUrl) => {
              updateProfile({
                imageUrl: downloadUrl,
                image: responseFilename,
              });
            }}
          />
          <div style={{ flex: 1 }}>
            <Form.Input
              required
              label="Organisation name"
              placeholder="Type your organisation name.."
              defaultValue={name}
              onChange={(e, { value }) => {
                updateProfile({ name: value });
              }}
            />
            <Form.Group widths={"equal"}>
              <Form.Input
                label="Phone number"
                placeholder="+44 0000 000000"
                defaultValue={businessProfile.phoneNumber}
                required
                onChange={(e, { value }) =>
                  updateProfile({
                    phoneNumber: value,
                  })
                }
              />
              <Form.Field>
                <Form.Input
                  label="Website, social media, or other web presence"
                  required
                  placeholder="https://www.example.com"
                  disabled={websiteUrlSkipped}
                  defaultValue={websiteUrl}
                  onChange={(e, { value }) =>
                    updateProfile({
                      websiteUrl: value,
                    })
                  }
                />
                <Checkbox
                  label="My business does not have a website yet"
                  style={{ marginTop: 15 }}
                  onChange={(e, { checked }) =>
                    updateProfile({
                      websiteUrlSkipped: checked,
                    })
                  }
                  checked={websiteUrlSkipped}
                />
              </Form.Field>
            </Form.Group>
          </div>
        </div>
        <Form>
          <Form.TextArea
            label="About the organisation"
            defaultValue={description}
            onChange={(e, { value }) =>
              updateProfile({
                description: value as string,
              })
            }
          />
        </Form>
        {util.validateUrl(websiteUrl ?? "") ? (
          <Popup
            content="SimplyDo will visit the website URL provided and use the content to generate a organisation description."
            trigger={
              <Button
                content="Generate organisation description from website"
                floated="right"
                loading={generatingDescription}
                onClick={generateDescription}
                size="tiny"
                style={{ marginTop: 5 }}
                icon="magic"
              />
            }
          />
        ) : null}
        <Divider hidden />
        <h4 style={{ marginBottom: 0 }}>Registered address</h4>
        <p
          style={{ color: "grey", marginTop: 5 }}
        >{`This must be your official registered address as recorded with Companies House (or other official register).  If you are a sole trader or another type of organisation, use your trading address or an address where your business can be reached.`}</p>
        <Form.Input
          required
          label="Street address"
          defaultValue={streetAddress}
          onChange={(e, { value }) =>
            updateProfile({
              address: { ...(address || {}), streetAddress: value },
            })
          }
        />
        <Form.Group>
          <Form.Select
            width="8"
            search
            clearable
            required
            label="Country"
            defaultValue={country}
            onChange={(e, { value }) => {
              updateProfile({
                address: {
                  ...(address || {}),
                  country: value as string,
                  region: value !== "GB" ? "" : businessProfile?.address?.region,
                },
              });
            }}
            placeholder="Select one"
            options={countries.map((c) => ({ key: c.iso2, flag: c.iso2.toLowerCase(), value: c.iso2, text: c.name }))}
          />
          {country === "GB" || country === "England" || country === "United Kingdom" ? (
            <Form.Select
              search
              width="8"
              required
              clearable
              label="Region"
              defaultValue={region}
              onChange={(e, { value }) =>
                updateProfile({
                  address: { ...(address || {}), region: value as string },
                })
              }
              options={regions.map((r) => ({
                key: r.value,
                value: r.value,
                text: r.name,
              }))}
            />
          ) : null}
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Input
            required
            label="City"
            defaultValue={city}
            onChange={(e, { value }) =>
              updateProfile({
                address: { ...(address || {}), city: value },
              })
            }
          />
          <Form.Input
            required
            label="Postcode"
            defaultValue={postcode}
            onChange={(e, { value }) =>
              updateProfile({
                address: { ...(address || {}), postcode: value },
              })
            }
          />
        </Form.Group>

        <Divider section hidden />
        <h4>Additional information</h4>
        <Form.Group widths="equal">
          <Form.Select
            required
            defaultValue={size}
            label={"Organisation size"}
            options={businessSizeOptions}
            onChange={(e, { value }) =>
              updateProfile({
                size: value as "micro" | "small" | "medium" | "large",
              })
            }
          />
          <Form.Select
            required
            defaultValue={type}
            label={"Organisation type"}
            options={businessTypeOptions}
            onChange={(e, { value }) =>
              updateProfile({
                type: value as "public" | "private" | "notForProfit" | "academic",
              })
            }
          />
        </Form.Group>
      </Form>
      <Form style={{ marginTop: 20 }}>
        <Form.Group widths={"equal"}>
          <Form.Input
            type="number"
            placeholder="2011"
            required
            error={foundingYear ? foundingYear < 1000 || foundingYear > currentYear : undefined}
            label="Organisation founding year"
            defaultValue={foundingYear}
            onChange={(e, { value }) => {
              const year = parseInt(value);
              let newStatus = status;
              if (year.toString().length === 4) {
                const diff = currentYear - year;
                if (diff < 1) {
                  newStatus = "startup";
                } else if (diff < 6) {
                  newStatus = "established_small";
                } else if (diff < 11) {
                  newStatus = "established_medium";
                } else {
                  newStatus = "established_large";
                }
              }
              updateProfile({
                foundingYear: year,
                status: newStatus,
              });
            }}
          />
          <Form.Select
            required
            value={status}
            label={"Organisation status"}
            options={businessStatusOptions}
            onChange={(e, { value }) =>
              updateProfile({
                status: value as
                  | "preStartup"
                  | "startup" // < 1 year
                  | "established_small" // 1-5 years
                  | "established_medium" // 6-10 years
                  | "established_large", // >10 years
              })
            }
          />
        </Form.Group>
      </Form>
      <Divider section />
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              placeholder="01234567"
              required
              label="Company registration number"
              defaultValue={creditsafeRegNo}
              onChange={(e, { value }) =>
                updateProfile({
                  creditsafeRegNo: value,
                })
              }
              disabled={creditsafeRegNoSkipped}
            />
            <Checkbox
              label="My business does not have a registration number"
              checked={creditsafeRegNoSkipped}
              onChange={(e, { checked }) =>
                updateProfile({
                  creditsafeRegNoSkipped: checked,
                })
              }
              style={{ marginTop: 10 }}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              required
              placeholder="GB-000000"
              label="VAT registration number"
              defaultValue={vatNo}
              onChange={(e, { value }) =>
                updateProfile({
                  vatNo: value,
                })
              }
              disabled={vatNoSkipped}
            />
            <Checkbox
              label="My business does not have a VAT number"
              checked={vatNoSkipped}
              onChange={(e, { checked }) =>
                updateProfile({
                  vatNoSkipped: checked,
                })
              }
              style={{ marginTop: 10 }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Divider section hidden />
      <h4>Accreditations</h4>
      <AccreditationsContainer>
        {(accreditations ?? []).map((accreditation) => (
          <Label key={accreditation} size="medium">
            <span>{constants.accreditations.find((a) => a.key === accreditation)?.text || accreditation}</span>
            <Icon
              name="delete"
              onClick={() =>
                updateProfile({
                  accreditations: accreditations?.filter((a) => a !== accreditation),
                })
              }
            />
          </Label>
        ))}
        <Dropdown
          style={{ fontSize: "small" }}
          button
          className="icon"
          labeled
          icon="add"
          scrolling
          text="Add accreditation"
        >
          <Dropdown.Menu>
            {constants.accreditations.map((accreditation) => (
              <Dropdown.Item
                key={accreditation.key}
                defaultValue={accreditation.key}
                onClick={() => {
                  if (accreditations?.includes(accreditation.key)) {
                    toast.error("This accreditation is already added");
                    return;
                  }
                  if (accreditation.key === "other") {
                    setAddingCustomAccreditation(true);
                  } else {
                    updateProfile({
                      accreditations: [...(accreditations ?? []), accreditation.key],
                    });
                  }
                }}
              >
                {accreditation.text}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {addingCustomAccreditation && (
          <>
            <Form.Input
              placeholder="Enter accreditation"
              defaultValue={customAccreditation}
              onChange={(e) => setCustomAccreditation(e.target.value)}
            />
            <Button
              onClick={() => {
                updateProfile({
                  accreditations: [...(accreditations ?? []), customAccreditation],
                });
                setCustomAccreditation("");
                setAddingCustomAccreditation(false);
              }}
            >
              Add
            </Button>
          </>
        )}
      </AccreditationsContainer>
      {tagsQuery.data?.length > 0 && (
        <>
          <Divider section hidden />
          <h4>Tags</h4>
          <TagModalChooser
            trigger={<TooltipButton compact icon="hashtag" content="Edit tags" size="tiny" />}
            tags={ownerTags}
            availableTags={tagsQuery.data?.filter((tag) => !tag?.disabledEntities?.includes("ideaBusinessProfiles"))}
            canEdit={true}
            addTag={(tag) => {
              addTagMutation.mutate({ params: ["ideaBusinessProfiles", tag._id, businessProfile._id] });
            }}
            removeTag={(tag) => {
              removeTagMutation.mutate({ params: ["ideaBusinessProfiles", tag._id, businessProfile._id] });
            }}
          />
        </>
      )}
    </>
  );
};
