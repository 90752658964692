import { SearchParams } from "simplydo/core";
import { OpenAPI } from "simplydo/interfaces";

type IIdeaImpactUpdate = Partial<OpenAPI.Schemas["IdeaImpact"]> & { authToken?: string };

export const ideas = (api) => ({
  get(id, success, fail, authToken) {
    api.maybeAuthenticatedRequest(
      "GET",
      `/ideas/${id}${authToken ? `?authToken=${authToken}` : ""}`,
      null,
      success,
      fail,
    );
  },
  update(id, data, success, fail) {
    api.maybeAuthenticatedRequest("PUT", `/ideas/${id}`, data, success, fail);
  },
  updateStatus(id, data, success, fail) {
    api.maybeAuthenticatedRequest("PUT", `/ideas/${id}/status`, data, success, fail);
  },
  assign(id, { authToken, authType }, success, fail) {
    api.authenticatedRequest("PUT", `/ideas/${id}/assign`, { authToken, authType }, success, fail);
  },
  delete(id, success, fail, authToken) {
    api.maybeAuthenticatedRequest(
      "DELETE",
      `/ideas/${id}${authToken ? `?authToken=${authToken}` : ""}`,
      null,
      success,
      fail,
    );
  },
  bulkDelete(ideaIds, success, fail) {
    api.authenticatedRequest("DELETE", "/ideas/bulk_delete", { ideas: ideaIds }, success, fail);
  },
  assigned(success, fail) {
    api.authenticatedRequest("GET", "/ideas/assigned", null, success, fail);
  },
  addCollaborator(id, userId, success, fail) {
    api.authenticatedRequest("PUT", `/ideas/${id}/members/${userId}`, null, success, fail);
  },
  addCollaboratorMulti(id, userIds, success, fail) {
    api.authenticatedRequest("PUT", `/ideas/${id}/members`, { users: userIds }, success, fail);
  },
  removeCollaborator(id, userId, success, fail) {
    api.authenticatedRequest("DELETE", `/ideas/${id}/members/${userId}`, null, success, fail);
  },
  updateOwner(id, userId, success, fail) {
    api.authenticatedRequest("PUT", `/ideas/${id}/owner/${userId}`, null, success, fail);
  },
  vote(id, add, success, fail) {
    api.authenticatedRequest(add ? "POST" : "DELETE", `/ideas/${id}/votes`, null, success, fail);
  },
  getComments(id, params, success, fail) {
    const page = params.page ? `?page=${params.page}` : "";
    const limit = params.limit ? `&limit=${params.limit}` : "";
    const query = `/ideas/${id}/comments${page}${limit}`;
    api.authenticatedRequest("GET", query, null, (data) => success && success(data.comments), fail);
  },
  postComment(id, data, success, fail) {
    api.authenticatedRequest("POST", `/ideas/${id}/comments`, data, success, fail);
  },
  deleteComment(id, commentId, success, fail) {
    api.authenticatedRequest("DELETE", `/ideas/${id}/comments/${commentId}`, null, success, fail);
  },
  pinComment(id, commentId, isPinning, success, fail) {
    api.authenticatedRequest("PUT", `/ideas/${id}/comments/${commentId}`, { isPinned: !!isPinning }, success, fail);
  },
  createAttachment(id, data, success, fail) {
    api.maybeAuthenticatedRequest("POST", `/ideas/${id}/attachments`, data, success, fail);
  },
  createMultipleAttachments(id, data, success, fail) {
    api.maybeAuthenticatedRequest("POST", `/ideas/${id}/attachments/multiple`, data, success, fail);
  },
  deleteAttachment(id, uuid, success, fail, authToken) {
    api.maybeAuthenticatedRequest(
      "DELETE",
      `/ideas/${id}/attachments/${uuid}${authToken ? `?authToken=${authToken}` : ""}`,
      null,
      success,
      fail,
    );
  },
  moveToChallenge(ideaIds, challengeId, success, fail) {
    const params = new SearchParams({
      ideas: ideaIds,
    });
    api.authenticatedRequest("PUT", `/ideas/challenges/${challengeId}${params.toSafeString()}`, null, success, fail);
  },
  getAssessment(id, success, fail) {
    api.authenticatedRequest("GET", `/ideas/${id}/assessment`, null, success, fail);
  },
  getAssessmentScore(id, success, fail) {
    api.authenticatedRequest("GET", `/ideas/${id}/assessment/score`, null, success, fail);
  },
  updateAssessment(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/ideas/${id}/assessment`, data, success, fail);
  },
  submitAssessment(id, success, fail) {
    api.authenticatedRequest("POST", `/ideas/${id}/assessment`, null, success, fail);
  },
  unsubmitAssessment(id, userId, success, fail) {
    api.authenticatedRequest("DELETE", `/ideas/${id}/assessments/${userId}`, null, success, fail);
  },
  commentAddReaction(id, commentId, emoji, success, fail) {
    api.authenticatedRequest("PUT", `/ideas/${id}/comments/${commentId}/reacts/${emoji}`, null, success, fail);
  },
  commentRemoveReaction(id, commentId, emoji, success, fail) {
    api.authenticatedRequest("DELETE", `/ideas/${id}/comments/${commentId}/reacts/${emoji}`, null, success, fail);
  },
  follow(id, status = true, success, fail) {
    api.authenticatedRequest(status ? "POST" : "DELETE", `/ideas/${id}/followers`, null, success, fail);
  },
  followers(id, params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/ideas/${id}/followers?${queryParams}`, null, success, fail);
  },
  addImpactMeasure(id: string, impactId: string, data: IIdeaImpactUpdate, success, fail) {
    api.maybeAuthenticatedRequest("PUT", `/ideas/${id}/impact/${impactId}`, data, success, fail);
  },
  removeImpactMeasure(id: string, impactId: string, measureId: string, data: IIdeaImpactUpdate, success, fail) {
    api.maybeAuthenticatedRequest(
      "DELETE",
      `/ideas/${id}/impact/${impactId}/measure/${measureId}`,
      data,
      success,
      fail,
    );
  },
  updateImpactMeasure(id: string, impactId: string, measureId: string, data: IIdeaImpactUpdate, success, fail) {
    api.maybeAuthenticatedRequest("POST", `/ideas/${id}/impact/${impactId}/measure/${measureId}`, data, success, fail);
  },
  summarise(id, success, fail) {
    api.authenticatedRequest("POST", `/ideas/${id}/summarise`, null, success, fail);
  },
  merge(data, success, fail) {
    api.authenticatedRequest("POST", "/ideas/merge", data, success, fail);
  },
  updateMergedIdea(id, data, success, fail) {
    api.authenticatedRequest("POST", `/ideas/${id}/merge`, data, success, fail);
  },
  removeIdeaFromMerge(parentId, childId, success, fail) {
    api.authenticatedRequest("DELETE", `/ideas/${parentId}/merge/${childId}`, null, success, fail);
  },
  createIdeaNameWithAI(id, success, fail) {
    api.authenticatedRequest("GET", `/ideas/${id}/ai/name`, null, success, fail);
  },
  getCopySuggestions(ideaId, fieldType, params, success, fail) {
    const search = new SearchParams(params);
    api.authenticatedRequest(
      "GET",
      `/ideas/${ideaId}/copy/suggest/${fieldType}${search.toSafeString()}`,
      null,
      success,
      fail,
    );
  },
  copyData(fromIdea, fromField, toIdea, toField, params, success, fail) {
    const search = new SearchParams(params);
    api.authenticatedRequest(
      "GET",
      `/ideas/${fromIdea}/${fromField}/copyTo/${toIdea}/${toField}${search.toSafeString()}`,
      null,
      success,
      fail,
    );
  },
  setDeadline(data, success, fail) {
    api.authenticatedRequest("POST", `/ideas/deadline`, data, success, fail);
  },
  removeDeadline(ideaIds, success, fail) {
    api.authenticatedRequest("DELETE", `/ideas/deadline`, { ideaIds }, success, fail);
  },
  getImpactStatements(ideaId, success, fail) {
    api.authenticatedRequest("GET", `/ideas/${ideaId}/impactStatement`, null, success, fail);
  },
  getActiveImpactStatement(ideaId, success, fail) {
    api.authenticatedRequest("GET", `/ideas/${ideaId}/impactStatement/active`, null, success, fail);
  },
  getImpactStatement(ideaId, statementId, success, fail) {
    api.authenticatedRequest("GET", `/ideas/${ideaId}/impactStatement/${statementId}`, null, success, fail);
  },
  updateImpactStatement(ideaId, statementId, data, success, fail) {
    api.authenticatedRequest("PUT", `/ideas/${ideaId}/impactStatement/${statementId}`, data, success, fail);
  },
  createImpactStatement(ideaId, data, success, fail) {
    api.authenticatedRequest("POST", `/ideas/${ideaId}/impactStatement`, data, success, fail);
  },
  updateImpactStatementStatus(ideaId, statementId, data, success, fail) {
    api.authenticatedRequest("PUT", `/ideas/${ideaId}/impactStatement/${statementId}/status`, data, success, fail);
  },
  deleteImpactStatement(ideaId, statementId, success, fail) {
    api.authenticatedRequest("DELETE", `/ideas/${ideaId}/impactStatement/${statementId}`, null, success, fail);
  },
});
