import React, { useState, useMemo, ReactElement } from "react";
import { Button, Dropdown, Popup, SemanticICONS } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import util from "utils/utils";
import { OpenAPI } from "simplydo/interfaces";

type RawAction<ItemType> = {
  checkPermission?: string;
  checkPermissionScope?: string;
  checkEnabledFeatures?: string[];
  superadminsBypassChecks?: boolean;
  checkSuperAdminPermission?: string;
  appear?: "single" | "multi" | "both";
} & (
  | {
      type?: "Item";
      name: string;
      icon?: SemanticICONS;
      onClick?: (items: ItemType[]) => void;
      onHover?: (items: ItemType[]) => React.ReactNode;
      link?: (items: ItemType[]) => string;
      disabled?: boolean;
      actions?: (RawAction<ItemType> | ((items: ItemType[]) => RawAction<ItemType>))[];
    }
  | {
      type: "Divider";
    }
);

export type Action<ItemType> = RawAction<ItemType> | ((items: ItemType[]) => RawAction<ItemType>);

export type ActionDropdownProps<ItemType> = {
  actions?: Action<ItemType>[] | ((currentItems: ItemType[]) => Action<ItemType>[]);
  items: ItemType[];
  type: "single" | "multi";
  user: OpenAPI.GET<"/users/me">["response"] | null;
  setDropdownOpen?: (open: boolean) => void;
  subMenu?: {
    name: string;
    icon?: SemanticICONS;
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    open: boolean;
  };
};

const SubMenuItem = styled(Dropdown.Item)`
  &&& {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.78571429rem 0.64285714rem !important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
    -webkit-touch-callout: none;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.95);
    }
  }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)<{ $subMenu: boolean }>`
  &&&& {
    ${({ $subMenu }) =>
      $subMenu
        ? `
        right: calc(100% + 10px) !important;
        left: auto !important;
      `
        : `
        position: relative;
        top: 0;
        padding: 0;
        box-shadow: none;
        border: none;
      `}
  }
`;

const ActionDropdown = <ItemType extends Record<string, any>>(props: ActionDropdownProps<ItemType>) => {
  const { user, actions: propActions, setDropdownOpen } = props;

  // Maintain open state just for submenus
  const [subMenuOpen, setSubMenuOpen] = useState("");

  const multiProps =
    !props.subMenu && props.type === "multi"
      ? {
          icon: "caret down",
          trigger: <div style={{ whiteSpace: "nowrap" }}>{util.pluralise(props.items.length, "Item", "Items")}</div>,
          style: {
            flexWrap: "nowrap",
            display: "flex",
            justifyContent: "flex-end",
          },
        }
      : {};
  const actions = useMemo(() => {
    if (typeof propActions === "function") {
      return propActions(props.items);
    }
    return propActions;
  }, [props.items, propActions]);

  if (!actions.length) {
    return <></>;
  }

  const dropdownActions = actions ? (
    actions
      .map((rawAction) => {
        if (typeof rawAction === "function") {
          return rawAction(props.items);
        }
        return rawAction;
      })
      .filter((action) => {
        if (action.appear && action.appear !== props.type && action.appear !== "both") {
          return false;
        }
        if (action.superadminsBypassChecks && util.hasPermission(user, "super.viewDashboard")) {
          return true;
        }
        if (action.checkSuperAdminPermission && util.hasPermission(user, action.checkSuperAdminPermission)) {
          return true;
        }
        if (action.checkPermission) {
          if (!user) {
            return false;
          }
          if (
            !util.hasPermission(user, action.checkPermission, action.checkPermissionScope ?? user.ownerOrganisation._id)
          ) {
            return false;
          }
        }
        if (action.checkEnabledFeatures) {
          if (!user) {
            return false;
          }
          if (!util.organisationFeaturesEnabled(user, action.checkEnabledFeatures)) {
            return false;
          }
        }
        return true;
      })
      .map((action, idx) => {
        if (action.type === "Divider") {
          return <Dropdown.Divider key={idx} />;
        }
        let HoverWrapper: React.FC<{ children?: ReactElement<any, any> }> = ({ children }) => children;
        if (action.onHover) {
          const UpdatedWrapper = ({ children }) => (
            <Popup content={<div>{action.onHover(props.items)}</div>} trigger={children} position="top center" />
          );
          HoverWrapper = UpdatedWrapper;
        }

        if (action.actions) {
          return (
            <HoverWrapper key={action.name}>
              <div>
                <ActionDropdown
                  key={action.name}
                  subMenu={{
                    name: action.name,
                    icon: action.icon,
                    open: subMenuOpen === action.name,
                    onClick: (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSubMenuOpen(subMenuOpen === action.name ? "" : action.name);
                    },
                  }}
                  {...props}
                  actions={action.actions}
                />
              </div>
            </HoverWrapper>
          );
        }
        return (
          <HoverWrapper key={action.name}>
            <Dropdown.Item
              key={action.name}
              icon={action.icon}
              content={action.name}
              onClick={(e) => {
                if (action.onClick) {
                  e.preventDefault();
                  e.stopPropagation();
                  action.onClick(props.items);
                }
              }}
              {...(action.link ? { as: Link, to: action.link(props.items) } : {})}
            />
          </HoverWrapper>
        );
      })
  ) : (
    <Dropdown.Item>No actions configured</Dropdown.Item>
  );

  if (props.subMenu) {
    return (
      <Dropdown
        icon={null}
        open={props.subMenu.open}
        onClose={() => {
          setSubMenuOpen("");
        }}
        trigger={
          <SubMenuItem
            icon={props.subMenu.icon ?? "caret left"}
            content={props.subMenu.name}
            onClick={(e) => props.subMenu.onClick(e)} // set this submenu as active in parent
          />
        }
        pointing={"right"}
        direction="left"
        style={{
          zIndex: 1000,
          width: "100%",
          padding: 0,
        }}
        disabled={props.items?.length === 0}
        {...multiProps}
      >
        {props.items?.length === 0 ? null : <StyledDropdownMenu $subMenu={true}>{dropdownActions}</StyledDropdownMenu>}
      </Dropdown>
    );
  }

  return (
    <Popup
      on="click"
      basic
      position="bottom right"
      className="ui dropdown active"
      icon={null}
      onClose={() => {
        setSubMenuOpen("");
        setDropdownOpen && setDropdownOpen(false);
      }}
      onOpen={() => {
        setDropdownOpen && setDropdownOpen(true);
      }}
      trigger={
        <Button
          compact
          icon="chevron down"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      }
      pointing={undefined}
      direction="left"
      style={{
        zIndex: 1000,
        padding: 0,
      }}
      disabled={props.items?.length === 0}
      {...multiProps}
    >
      {props.items?.length === 0 ? null : (
        <StyledDropdownMenu $subMenu={false} className="visible">
          {dropdownActions}
        </StyledDropdownMenu>
      )}
    </Popup>
  );
};

export default ActionDropdown;
