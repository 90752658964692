import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Loader, Container, Grid, Input, Card, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import useTheme from "theme/useTheme";
import toast from "react-hot-toast";
import util from "utils/utils";
import useThrottle from "utils/useThrottle";
import api from "api";
import { EmptyBox } from "components/lib/UI";
import { useTranslation } from "react-i18next";

import GroupCard, { GroupCardListView } from "components/lib/Cards/GroupCard";
import { useAppSelector } from "store";

const Groups = () => {
  const [yourGroups, setYourGroups] = useState([]);
  const [discoverGroups, setDiscoverGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const theme = useTheme();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);

  const getGroups = useThrottle(
    () => {
      api.journey.record("groups", null, "viewGroupsPage");
      setLoading(true);
      const searchFilter = (g) => {
        const groupName = (g.name || "").toLowerCase();
        return !searchInput || groupName.includes(searchInput.toLowerCase());
      };
      api.groups.discover(
        (groups) => {
          setYourGroups(groups.filter((g) => searchFilter(g) && g.isInGroup));
          setDiscoverGroups(groups.filter((g) => searchFilter(g) && !g.isInGroup));
          setLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setLoading(false);
        },
      );
    },
    400,
    [searchInput],
    { useLeadingCall: true },
  );

  useEffect(() => getGroups(), [getGroups]);

  return (
    <div>
      <Helmet title="Groups" />
      <div style={{ marginTop: 20 }}>
        <Container>
          <Grid stackable>
            <Grid.Column computer={11}>
              <h1 style={{ color: "#3b3b3b", margin: "0px" }}>Groups</h1>
            </Grid.Column>
            <Grid.Column computer={5} textAlign="right" verticalAlign="bottom">
              {util.hasPermission(user, "org.createGroups", user.ownerOrganisation._id) && (
                <Button
                  size={theme?.sizes?.isMobile ? "tiny" : "medium"}
                  floated="right"
                  as={Link}
                  to="/groups/new"
                  icon="plus"
                  content={t("groups.discover.create")}
                />
              )}
            </Grid.Column>
          </Grid>
        </Container>
      </div>
      <Container style={{ marginTop: "1rem" }}>
        {loading && <Loader active />}
        {!loading && !yourGroups.length && !discoverGroups.length ? (
          <EmptyBox>
            <h2>{searchInput.length ? t("groups.groups.noneTerm") : t("groups.groups.none")}</h2>
          </EmptyBox>
        ) : null}
        <Input
          theme={theme}
          value={searchInput}
          placeholder="Search groups..."
          icon="search"
          style={{ width: 370 }}
          onChange={(e, { value }) => setSearchInput(value)}
        />
        <Grid
          style={{ marginTop: "1rem" }}
          columns={yourGroups.length && discoverGroups.length && theme.sizes.isComputer ? 2 : 1}
        >
          <Grid.Row>
            {yourGroups.length ? (
              <Grid.Column size={8}>
                <h2 style={{ marginBottom: 20 }}>{t("groups.groups.member")}</h2>
                {yourGroups.map((group) => (
                  <GroupCardListView key={group._id} group={group} />
                ))}
              </Grid.Column>
            ) : null}
            {discoverGroups.length ? (
              <Grid.Column size={8} style={!theme.sizes.isComputer ? { marginTop: 20 } : {}}>
                <h2 style={{ marginBottom: 20 }}>{t("groups.discover.title")}</h2>
                <Card.Group itemsPerRow={theme.sizes.isMobile ? 1 : 2}>
                  {discoverGroups.map((group) => (
                    <GroupCard key={group._id} group={group} />
                  ))}
                </Card.Group>
              </Grid.Column>
            ) : null}
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default Groups;
