import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Accordion, Button, Dropdown, Icon, Label } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import api, { asMutation } from "api";
import util from "utils/utils";
import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import { UserChip } from "components/lib/Chips";
import moment from "moment";
import toast from "react-hot-toast";

type QuestionListProps = {
  openOn?: string;
  questions: OpenAPI.Schemas["Question"][];
  challenge: OpenAPI.Schemas["Challenge"];
  setQuestionToEdit?: (question: OpenAPI.Schemas["Question"]) => void;
  setSearchParams?: (params: Record<string, string>) => void;
};

const QuestionList = ({ openOn, questions, challenge, setQuestionToEdit, setSearchParams }: QuestionListProps) => {
  const { t } = useTranslation();
  const [activeQuestions, setActiveQuestions] = useState(openOn ? [openOn] : []);
  const user = useAppSelector((state) => state.user);
  const isEditable = !!setQuestionToEdit;

  const canManageQuestions =
    util.hasPermission(user, "challenge.manageQuestions", challenge._id) ||
    util.hasPermission(user, "org.manageChallenges", challenge.organisation);

  const reorderQuestionsMutation = useMutation({
    mutationKey: ["challenges", challenge._id, "questions", "order"],
    mutationFn: asMutation(api.challenges.reorderQuestion)!,
    onSuccess: () => {
      api.queryClient.invalidateQueries({
        queryKey: ["challenge", challenge._id, "questions"],
      });
    },
  });

  const publishMutation = useMutation({
    mutationKey: ["challenges", challenge._id, "questions", "publish"],
    mutationFn: asMutation(api.challenges.publishQuestion)!,
    onSuccess: (result) => {
      api.queryClient.setQueryData(["challenge", challenge._id, "questions"], (prev: any) => ({
        ...prev,
        questions: prev.questions.map((q) => (q._id === result.question._id ? result.question : q)),
      }));
    },
  });

  const unpublishMutation = useMutation({
    mutationKey: ["challenges", challenge._id, "questions", "unpublish"],
    mutationFn: asMutation(api.challenges.unpublishQuestion)!,
    onSuccess: (result) => {
      api.queryClient.setQueryData(["challenge", challenge._id, "questions"], (prev: any) => ({
        ...prev,
        questions: prev.questions.map((q) => (q._id === result.question._id ? result.question : q)),
      }));
      api.queryClient.invalidateQueries({
        queryKey: ["challenge", challenge._id, "questions"],
      });
    },
  });

  const deleteQuestionMutation = useMutation({
    mutationKey: ["challenges", challenge._id, "questions"],
    mutationFn: asMutation(api.challenges.deleteQuestion)!,
    onSuccess: (result) => {
      api.queryClient.setQueryData(["challenge", challenge._id, "questions"], (prev: any) => ({
        ...prev,
        questions: prev.questions.filter((q) => q._id !== result.deleted),
      }));
    },
  });

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination?.index;
        if (destinationIndex === undefined) return;

        const sourceQuestion = questions?.find((question) => question._id === result.draggableId);

        api.queryClient.setQueryData(["challenge", challenge._id, "questions"], (prev: any) => ({
          ...prev,
          questions: prev.questions
            .filter((q) => q._id !== result.draggableId)
            .flatMap((q) => {
              if (q.order === destinationIndex) {
                if (sourceIndex < destinationIndex) {
                  return [q, sourceQuestion];
                }
                return [sourceQuestion, q];
              }
              return [q];
            }),
        }));

        reorderQuestionsMutation.mutate({
          params: [challenge._id, result.draggableId, destinationIndex],
        });
      }}
    >
      <Droppable droppableId="questions" direction="vertical">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: "flex", gap: 8 }}>
            <Accordion fluid styled exclusive={false}>
              {questions?.map((question, questionIndex) => {
                return (
                  <Draggable
                    key={question._id}
                    draggableId={question._id}
                    index={questionIndex}
                    isDragDisabled={!isEditable || !canManageQuestions}
                  >
                    {(draggable) => (
                      <div ref={draggable.innerRef} {...draggable.draggableProps} {...draggable.dragHandleProps}>
                        <Accordion.Title
                          active={activeQuestions.includes(question._id)}
                          index={question.order}
                          style={{
                            color: "rgba(0, 0, 0, .87)",
                          }}
                          onClick={() => {
                            setActiveQuestions((prev) => {
                              if (prev.includes(question._id)) {
                                setSearchParams?.({});
                                return prev.filter((i) => i !== question._id);
                              }
                              setSearchParams?.({ question: question._id });
                              return [...prev, question._id];
                            });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                          >
                            <div style={{ maxWidth: "85%", alignSelf: "center" }}>
                              {isEditable && canManageQuestions ? (
                                <div style={{ marginBottom: 1, fontWeight: "normal" }}>
                                  <UserChip user={question.ownerCreatedBy} compact="very" /> asked on{" "}
                                  {moment(question.createdAt).format("ll")}:
                                </div>
                              ) : null}
                              <div>
                                <Icon name="dropdown" />
                                <span
                                  style={{
                                    fontSize: "1.1em",
                                  }}
                                >
                                  {question.question}
                                </span>
                                <span style={{ marginLeft: 12 }} />
                                <span style={{ position: "relative", top: -2 }}>
                                  {!question.answer ? <Label content="Awaiting answer" size="mini" /> : null}
                                  {canManageQuestions ? (
                                    !question.published ? (
                                      <Label content="Not public" size="mini" color="yellow" />
                                    ) : (
                                      <Label content="Public" size="mini" color="green" />
                                    )
                                  ) : null}
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: isEditable ? 5 : 0,
                                gap: 3,
                              }}
                            >
                              <Button
                                size="tiny"
                                basic
                                icon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.navigator?.clipboard
                                    ?.writeText?.(
                                      `${window.location.origin}/challenges/${challenge._id}/questions?question=${question._id}`,
                                    )
                                    .then(
                                      () => toast.success("Link copied to your clipboard"),
                                      () => toast.error("Unable to copy the link"),
                                    );
                                }}
                              >
                                <Icon name="linkify" />
                              </Button>
                              {isEditable && canManageQuestions ? (
                                <Dropdown
                                  direction="left"
                                  icon={null}
                                  trigger={
                                    <Button basic icon size="tiny">
                                      <Icon name="ellipsis horizontal" />
                                    </Button>
                                  }
                                >
                                  <Dropdown.Menu>
                                    {question.published ? (
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          util
                                            .confirm(
                                              "Make question non-public",
                                              "Are you sure you want to unpublish this question? Users will no longer be able to see the question or its response.",
                                            )
                                            .then(() => {
                                              unpublishMutation.mutate({
                                                params: [challenge._id, question._id],
                                              });
                                            })
                                            .catch(() => {});
                                        }}
                                      >
                                        <Icon name="undo" />
                                        Make non-public
                                      </Dropdown.Item>
                                    ) : question.answer ? (
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          util
                                            .confirm(
                                              "Make question public",
                                              `Are you sure you want to publish this question? Any user with access to this ${t("generic.challenge")} will be able to see the question and answer.`,
                                            )
                                            .then(() => {
                                              publishMutation.mutate({
                                                params: [challenge._id, question._id],
                                              });
                                            })
                                            .catch(() => {});
                                        }}
                                      >
                                        <Icon name="send" />
                                        Make public
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setQuestionToEdit(question);
                                        }}
                                      >
                                        <Icon name="reply all" />
                                        Answer now
                                      </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setQuestionToEdit(question);
                                      }}
                                    >
                                      <Icon name="edit" />
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        util
                                          .confirm("Delete question", "Are you sure you want to delete this question?")
                                          .then(() => {
                                            deleteQuestionMutation.mutate({
                                              params: [challenge._id, question._id],
                                            });
                                          })
                                          .catch(() => {});
                                      }}
                                    >
                                      <Icon name="trash" />
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : null}
                            </div>
                          </div>
                        </Accordion.Title>
                        <Accordion.Content active={activeQuestions.includes(question._id)}>
                          {question.answer ? (
                            <>
                              <RichTextViewer
                                content={question.answer || ""}
                                forType="challenge"
                                forId={challenge._id}
                              />
                              <span
                                style={{
                                  color: "#ababab",
                                  fontSize: "0.8em",
                                  display: "block",
                                  marginTop: 8,
                                }}
                              >
                                Answered {moment(question.publishedAt).format("ll")}
                              </span>
                            </>
                          ) : (
                            <>
                              <p style={{ color: "#ababab" }}>Question has not been answered yet.</p>
                              {isEditable && canManageQuestions ? (
                                <Button
                                  size="tiny"
                                  basic
                                  onClick={() => {
                                    setQuestionToEdit(question);
                                  }}
                                >
                                  <Icon name="reply all" />
                                  Answer now
                                </Button>
                              ) : null}
                            </>
                          )}
                        </Accordion.Content>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Accordion>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default QuestionList;
