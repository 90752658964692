import React, { CSSProperties, Dispatch, SetStateAction } from "react";
import { useAppSelector } from "store";
import { OpenAPI } from "simplydo/interfaces";
import { useTranslation } from "react-i18next";
import { Icon, Accordion, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { StatusLabel, IdeaUpdateStatus } from "components/lib/Cards/IdeaCard/ListView/styles";
import styled from "styled-components";
import util from "utils/utils";
import moment from "moment";

const IdeaCardComponent = styled.div<{ $coverImage?: string }>`
  height: 55px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  .cover-image {
    border-radius: 5px;
    background-image: url(${({ $coverImage }) => $coverImage});
    background-size: cover;
    background-position: center center;
    margin-right: 5px;
    height: 55px;
    width: 55px;
  }
  .idea-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
  .idea-name {
    display: block;
    font-weight: bold;
    color: black;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > .ui.button {
    height: 35px;
    font-size: 0.95em;
  }
`;

const ConciseIdeaCard = ({ idea, isYours }) => {
  const { t } = useTranslation();
  return (
    <IdeaCardComponent
      $coverImage={util.mixinCssUrlFallback(util.ideaCoverImage(idea), util.ideaCoverImage())}
      as={Link}
      to={`/ideas/${idea._id}`}
    >
      <div className="cover-image" />
      <div className="idea-info">
        <span className="idea-name">{idea.name}</span>
        <IdeaUpdateStatus>
          {idea.isSubmitted ? (
            <>
              {/* @ts-ignore */}
              <StatusLabel color="green" />
              <span>
                Submitted{` `}
                {moment(idea.submittedAt).format("DD/MM/YY")}
              </span>
            </>
          ) : (
            <>
              {/* @ts-ignore */}
              <StatusLabel color="yellow" />
              <span>
                Updated{` `}
                {moment(idea.updatedAt).format("DD/MM/YY")}
              </span>
            </>
          )}
        </IdeaUpdateStatus>
      </div>
      <Button
        content={idea.isSubmitted || !isYours ? "View" : `Continue ${t("generic.idea")}`}
        basic={idea.isSubmitted || !isYours}
      />
    </IdeaCardComponent>
  );
};

const IdeaCardGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${IdeaCardComponent} {
    margin-bottom: 10px;
    ${({ theme }) =>
      !theme.sizes.isMobile
        ? `
      width: calc(50% - 0.5rem);
      &:nth-child(2n) {
        margin-left: 1rem;
      }
    `
        : `
      width: 100%;
    `}
  }
`;

type ChallengeMyIdeasProps = {
  myIdeas: OpenAPI.GET<"/challenges/{id}/ideas">["response"]["ideas"][0][];
  style?: CSSProperties;
  showMyIdeas: boolean;
  setShowMyIdeas: Dispatch<SetStateAction<boolean>>;
};

const ChallengeMyIdeas = ({ myIdeas = [], style, showMyIdeas, setShowMyIdeas }: ChallengeMyIdeasProps) => {
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  if (!myIdeas.length) return null;
  return (
    <Accordion style={style}>
      <Accordion.Title
        active={showMyIdeas}
        index={0}
        onClick={() => setShowMyIdeas((prev) => !prev)}
        style={{ paddingBottom: 0 }}
      >
        <h3 style={{ display: "inline" }}>{t("users.preferences.yourIdeas.yours")}</h3>{" "}
        <Icon style={{ marginLeft: 5 }} name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={showMyIdeas}>
        <IdeaCardGroup>
          {myIdeas.map((idea, index) => (
            <ConciseIdeaCard isYours={util.canEditIdea(user, idea)} key={index} idea={idea} />
          ))}
        </IdeaCardGroup>
      </Accordion.Content>
    </Accordion>
  );
};

export default ChallengeMyIdeas;
