export const boards = (api) => ({
  getProjectLanes(forId, success, fail) {
    api.authenticatedRequest("GET", `/boards/${forId}/lanes`, null, success, fail);
  },
  importProjectLanes(forId, data, success, fail) {
    api.authenticatedRequest("POST", `/boards/${forId}/lanes/import`, data, success, fail);
  },
  createProjectLane(forId, data, success, fail) {
    api.authenticatedRequest("POST", `/boards/${forId}/lanes`, data, success, fail);
  },
  updateProjectLane(forId, laneId, data, success, fail) {
    api.authenticatedRequest("PUT", `/boards/${forId}/lanes/${laneId}`, data, success, fail);
  },
  deleteProjectLane(forId, laneId, success, fail) {
    api.authenticatedRequest("DELETE", `/boards/${forId}/lanes/${laneId}`, null, success, fail);
  },
  getProjectLaneImpactMeasures(forId, success, fail) {
    api.authenticatedRequest("GET", `/boards/${forId}/impact`, null, success, fail);
  },
  updateProjectIdea(forId, ideaId, laneId, data, success, fail) {
    api.authenticatedRequest("PUT", `/boards/${forId}/ideas/${ideaId}/lanes/${laneId}`, data, success, fail);
  },
  updateProjectIdeasMulti(
    forId,
    data: {
      ideaIds?: string[];
      targetLane: string;
      originalLane?: string;
      sendAnnouncements?: Array<string>;
    },
    success,
    fail,
  ) {
    api.authenticatedRequest("PUT", `/boards/${forId}/ideas/lanes`, data, success, fail);
  },
  updateProjectIdeaDeadline(forId, ideaId, deadline, success, fail) {
    api.authenticatedRequest("PUT", `/boards/${forId}/ideas/${ideaId}/deadline`, { deadline }, success, fail);
  },
  updateProjectIdeasDeadline(forId, data: { ideaIds: string[]; deadline: Date }, success, fail) {
    api.authenticatedRequest("PUT", `/boards/${forId}/ideas/deadline`, data, success, fail);
  },
  updateProjectIdeaReviewDate(forId, ideaId, reviewDate, success, fail) {
    api.authenticatedRequest("PUT", `/boards/${forId}/ideas/${ideaId}/reviewDate`, { reviewDate }, success, fail);
  },
  searchProjectAssignees(forId, query, success, fail) {
    api.authenticatedRequest("GET", `/boards/${forId}/assignees/search?query=${query}`, null, success, fail);
  },
  bulkAssignProjectIdeas(forId, data: { ideaIds: string[]; userIds: string[] }, success, fail) {
    api.authenticatedRequest("PUT", `/boards/${forId}/ideas/assignees`, data, success, fail);
  },
  assignProjectIdea(forId, ideaId, userId, data, success, fail) {
    api.authenticatedRequest("PUT", `/boards/${forId}/ideas/${ideaId}/assignees/${userId}`, data, success, fail);
  },
  unassignProjectIdea(forId, ideaId, userId, success, fail) {
    api.authenticatedRequest("DELETE", `/boards/${forId}/ideas/${ideaId}/assignees/${userId}`, null, success, fail);
  },
  getProjectIdeaEvents(forId, ideaId, success, fail) {
    api.authenticatedRequest("GET", `/boards/${forId}/ideas/${ideaId}/events`, null, success, fail);
  },
  getProjectComments(forId, ideaId, params, success, fail) {
    const page = params.page ? `?page=${params.page}` : "";
    const limit = params.limit ? `&limit=${params.limit}` : "";
    const query = `/boards/${forId}/ideas/${ideaId}/comments${page}${limit}`;
    api.authenticatedRequest("GET", query, null, success, fail);
  },
  postProjectComment(forId, ideaId, data, success, fail) {
    api.authenticatedRequest("POST", `/boards/${forId}/ideas/${ideaId}/comments`, data, success, fail);
  },
  deleteProjectComment(forId, ideaId, commentId, success, fail) {
    api.authenticatedRequest("DELETE", `/boards/${forId}/ideas/${ideaId}/comments/${commentId}`, null, success, fail);
  },
  getBoardEvents(forId, params, success, fail) {
    const { page = 1, limit = 10 } = params;
    api.authenticatedRequest("GET", `/boards/${forId}/events?page=${page}&limit=${limit}`, null, success, fail);
  },
  markBoardEventsAsSeen(forId, success, fail) {
    api.authenticatedRequest("POST", `/boards/${forId}/events`, null, success, fail);
  },
  markBoardEventAsInteracted(forId, eventId, success, fail) {
    api.authenticatedRequest("POST", `/boards/${forId}/events/${eventId}`, null, success, fail);
  },
  reorderLaneIdeas(forId, laneId, sort, sortDirection, success, fail) {
    api.authenticatedRequest(
      "PUT",
      `/boards/${forId}/lanes/${laneId}/ideas/order?sort=${sort}&sortDirection=${sortDirection}`,
      null,
      success,
      fail,
    );
  },
});
