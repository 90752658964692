import React, { useState, useEffect, useCallback } from "react";
import { Button, Icon, Popup, Message, Dropdown, Divider, Segment, Grid } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import styled from "styled-components";
import toast from "react-hot-toast";
import api, { asQuery, asMutation } from "api";
import actions from "actions";

import utils from "utils/utils";
import BusinessProfileSelector from "./ProfileSelector";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "store";
import { Overview } from "components/lib/IdeaBusinessProfileModal/Tabs/Overview";
import IdeaBusinessProfileModal from "components/lib/IdeaBusinessProfileModal/IdeaBusinessProfileModal";
import { BusinessProfileSearcher } from "./BusinessProfileSearcher";
import { useQuery, useMutation } from "@tanstack/react-query";
import { ContactLayout, AddressLayout } from "components/lib/IdeaBusinessProfileModal/Tabs/Contact";
import { UserChip } from "components/lib/Chips";

const IdeaSection = styled.div`
  > h5 {
    display: block;
    margin: 0;
  }
  > .title-explainer {
    color: gray;
    display: block;
    margin: 2.5px 0 20px;
  }
`;

// `updateIdea` can be undefined if the idea is open in an idea preview, in those cases the idea is never editable and no update function is available. However, this is not an issue as the component doesn't expose any editing functionality in that case anyway
type IIdeaBusinessProfileComponent = {
  idea: OpenAPI.Schemas["Idea"];
  isEditing?: boolean;
  style?: object;
  updateIdea?: (updatedValue: any, path: string[], suppressSave?: boolean) => void;
};

const IdeaBusinessProfileComponent = ({ idea, isEditing, style, updateIdea }: IIdeaBusinessProfileComponent) => {
  // Track state of the profile editor - what stage the user is at
  const [creditsafeEnabled, setCreditsafeEnabled] = useState(false);

  const profilesQuery = useQuery({
    queryKey: ["get", "users", "profiles"],
    queryFn: asQuery(api.users.getExistingProfiles),
  });

  const [generatingCreditReport, setGeneratingCreditReport] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    openInEditMode: false,
    defaultTab: 0,
  });

  const ideaBusinessProfile = idea["lockedOwnerIdeaBusinessProfile"];
  const ideaBusinessProfileDetails = idea["ideaBusinessProfileDetails"];
  const [businessProfile, setBusinessProfile] = useState<OpenAPI.Schemas["IdeaBusinessProfile"]>(ideaBusinessProfile);
  const {
    name,
    address = {},
    addresses,
    creditsafeRegNo,
    foundingYear,
    vatNo,
    phoneNumber,
    contacts,
    creditsafeCompanyId,
    websiteUrl,
    websiteUrlSkipped,
    type,
    status,
    size,
    vatNoSkipped,
    creditsafeRegNoSkipped,
    creditsafeReportUrl,
  } = businessProfile || {};

  const { country, city, streetAddress, postcode, region } = address || {};

  const primaryContact = contacts?.find((contact) => contact.id === ideaBusinessProfileDetails?.primaryContact);
  const tradingAddress = ideaBusinessProfileDetails?.tradingAddress
    ? [{ ...address, id: "registeredAddress" }, ...(addresses || [])]?.find(
        (address) => address.id === ideaBusinessProfileDetails?.tradingAddress,
      )
    : null;

  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const challengeId = idea?.challenge;
  const hasReportPermissions =
    utils.hasPermission(user, "challenge.viewAssessments", idea.challenge) ||
    utils.canManageChallenge(user, idea.ownerChallenge) ||
    idea.isAssessor;
  const canEditProfile = utils.hasPermission(user, "ideaBusinessProfile.editDetails", ideaBusinessProfile?._id);

  const onProfileUpdated = (businessProfile) => {
    api.queryClient.setQueryData(["get", "users", "profiles", businessProfile._id], {
      ideaBusinessProfile: businessProfile,
    });
    api.queryClient.invalidateQueries({ queryKey: ["get", "users", "profiles"] });
  };

  useEffect(() => {
    api.challenges.get(
      challengeId,
      (challenge) => {
        setCreditsafeEnabled(challenge.creditsafeAvailable);
      },
      () => {
        setCreditsafeEnabled(false);
      },
    );
  }, [challengeId]);

  // Attach a new, or previously created organisation profile
  // Once done, update the idea locally and display the preview of the organisation profile
  const attachBusinessProfile = useCallback(
    (ideaBusinessProfile) => {
      setBusinessProfile(ideaBusinessProfile);
      updateIdea(ideaBusinessProfile._id, ["ideaBusinessProfile"]);
      updateIdea(ideaBusinessProfile, ["lockedOwnerIdeaBusinessProfile"], true);
    },
    [updateIdea],
  );

  const unattachBusinessProfile = useCallback(() => {
    updateIdea(null, ["ideaBusinessProfile"]);
    updateIdea(null, ["ideaBusinessProfileDetails"]);
    updateIdea(null, ["lockedOwnerIdeaBusinessProfile"], true);
    setBusinessProfile(null);
  }, [updateIdea]);

  const viewReportForCompany = useCallback(() => {
    if (ideaBusinessProfile?._id) {
      setGeneratingCreditReport(true);
      api.users.generateIdeaBusinessProfileCreditReport(
        ideaBusinessProfile?._id,
        ({ success, creditsafeReportUrl }) => {
          if (!success) {
            toast.error("Could not generate CreditSafe report");
            setGeneratingCreditReport(false);
            return;
          }
          // undefined if opened in idea preview, the update is only really for the frontend anyway
          // The actual caching is built into the API
          updateIdea?.(creditsafeReportUrl, ["lockedOwnerIdeaBusinessProfile", "creditsafeReportUrl"], true);
          setGeneratingCreditReport(false);
          window.open(creditsafeReportUrl, "_blank", "noopener noreferrer");
        },
        (err) => {
          toast.error(err.message);
          setGeneratingCreditReport(false);
        },
      );
    }
  }, [ideaBusinessProfile?._id, updateIdea]);

  const createProfileMutation = useMutation({
    mutationKey: ["post", "users", "profiles"],
    mutationFn: asMutation(api.users.createProfile),
    onSuccess: ({ ideaBusinessProfile }) => {
      toast.success("Profile created");
      dispatch(
        actions.user.updatePermissions(ideaBusinessProfile._id, [
          "ideaBusinessProfile.viewDetails",
          "ideaBusinessProfile.editDetails",
          "ideaBusinessProfile.viewIdeas",
          "ideaBusinessProfile.editIdeas",
          "ideaBusinessProfile.viewMembers",
          "ideaBusinessProfile.editMembers",
          "ideaBusinessProfile.deleteProfile",
        ]),
      );
      onProfileUpdated(ideaBusinessProfile);
    },
    onError: () => {
      toast.error("Failed to create profile");
    },
  });

  const createBusinessProfile = useCallback(
    (profile) => {
      createProfileMutation.mutate(
        {
          params: [profile],
        },
        {
          onSuccess: ({ ideaBusinessProfile }) => {
            setModalConfig({
              openInEditMode: true,
              defaultTab: 0,
            });
            setBusinessProfile(ideaBusinessProfile);
            setIsCreationModalOpen(false);
            setIsProfileModalOpen(true);
          },
        },
      );
    },
    [createProfileMutation],
  );

  if (!isEditing && !ideaBusinessProfile) {
    return null;
  }

  const currentYear = new Date().getFullYear();

  const requiredInfoFields = [
    { label: "Name", value: name },
    { label: "Website, social media, or other web presence", value: websiteUrl, skipped: websiteUrlSkipped },
    { label: "Phone number", value: phoneNumber },
    { label: "Street address", value: streetAddress },
    { label: "Country", value: country },
    { label: "Region", value: region, skipped: country !== "GB" },
    { label: "City", value: city },
    { label: "Postcode", value: postcode },
    { label: "Organisation size", value: size },
    { label: "Organisation type", value: type },
    { label: "Organisation status", value: status },
    {
      label: "Organisation founding year",
      value: foundingYear && foundingYear > 999 && foundingYear <= currentYear ? foundingYear : "",
    },
    { label: "Company registration number", value: creditsafeRegNo, skipped: creditsafeRegNoSkipped },
    { label: "VAT registration number", value: vatNo, skipped: vatNoSkipped },
  ];
  const isMissingRequiredInfo = requiredInfoFields.some((field) => !field.value && !field.skipped);

  const requiredContactFields = [
    { label: "Contact name", value: primaryContact?.fullName },
    { label: "Contact position or job title", value: primaryContact?.jobTitle },
    { label: "Contact phone number", value: primaryContact?.phoneNumber },
    { label: "Contact email", value: primaryContact?.email },
  ];
  const isMissingRequiredContactInfo = requiredContactFields.some((field) => !field.value);

  const requiredAddressFields = [
    { label: "Street address", value: tradingAddress?.streetAddress },
    { label: "City", value: tradingAddress?.city },
    { label: "Country", value: tradingAddress?.country },
    { label: "Postcode", value: tradingAddress?.postcode },
  ];
  const isMissingRequiredAddressInfo = requiredAddressFields.some((field) => !field.value);

  return (
    <IdeaSection style={style}>
      {isProfileModalOpen ? (
        <IdeaBusinessProfileModal
          config={modalConfig}
          businessProfile={businessProfile}
          onClose={() => {
            setIsProfileModalOpen(false);
            updateIdea(businessProfile._id, ["ideaBusinessProfile"]);
          }}
          onSave={(savedProfile) => {
            setBusinessProfile(savedProfile);
            attachBusinessProfile(savedProfile);
          }}
          onDelete={() => {
            updateIdea(null, ["lockedOwnerIdeaBusinessProfile"], true);
            updateIdea(null, ["ideaBusinessProfile"]);
            setIsProfileModalOpen(false);
            setBusinessProfile(null);
          }}
        />
      ) : null}
      {isCreationModalOpen ? (
        <BusinessProfileSearcher
          onClose={() => setIsCreationModalOpen(false)}
          onSelect={(profile) => createBusinessProfile(profile)}
          creditsafeEnabled={creditsafeEnabled}
          ideaId={idea._id}
        />
      ) : null}
      <h3>
        Organisation details
        {isEditing && idea.ideaTemplate?.body?.find((section) => section.type === "businessProfile")?.isRequired ? (
          <Popup
            size="small"
            trigger={
              <Icon name="asterisk" style={{ position: "relative", top: -5, marginLeft: 3 }} color="red" size="tiny" />
            }
            content={`This field must be filled in to submit your ${t("generic.idea")}`}
          />
        ) : null}
      </h3>
      {canEditProfile && isEditing ? (
        <span className="title-explainer">
          Please provide your organisation's details to supplement your {t("generic.idea")}.
        </span>
      ) : (
        <div style={{ marginBottom: 5 }} />
      )}

      {isEditing && ideaBusinessProfile && isMissingRequiredInfo ? (
        canEditProfile ? (
          <Message
            icon="exclamation triangle"
            warning
            size="small"
            content={
              <>
                <Message.Header>Your organisation profile is missing required information</Message.Header>
                <p>
                  The following parts of your organisation's profile are still missing or incomplete. Please make sure
                  to complete them before submitting your {t("generic.idea")}.
                </p>
                <ul>
                  {requiredInfoFields.map((field) => {
                    if (field.value || field.skipped) {
                      return null;
                    }
                    return <li key={field.label}>{field.label}</li>;
                  })}
                </ul>
                <Button
                  icon="pencil"
                  basic
                  size="small"
                  onClick={() => {
                    setModalConfig({
                      openInEditMode: true,
                      defaultTab: 0,
                    });
                    setIsProfileModalOpen(true);
                  }}
                  content="Edit profile"
                />
              </>
            }
          />
        ) : (
          <Message warning icon>
            <Icon name="exclamation triangle" />
            <Message.Content>
              <Message.Header>Your organisation profile is missing required information</Message.Header>
              <p>
                The following parts of your organisation's profile are still missing or incomplete. They need to be
                completed before submitting your {t("generic.idea")}, however you do not have permission to edit the
                profile itself.
                <ul>
                  {requiredInfoFields.map((field) => {
                    if (field.value || field.skipped) {
                      return null;
                    }
                    return <li key={field.label}>{field.label}</li>;
                  })}
                </ul>
                The following users are members of the profile and have the necessary permissions to amend the required
                information.
                <ul>
                  {ideaBusinessProfile?.ownerUsers?.map((profileUser) => (
                    <li key={profileUser._id}>
                      <UserChip user={profileUser} compact="very" />
                    </li>
                  ))}
                </ul>
              </p>
            </Message.Content>
          </Message>
        )
      ) : null}

      {isEditing ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            alignItems: profilesQuery.data?.profiles ? "flex-end" : "stretch",
          }}
        >
          <BusinessProfileSelector
            key={ideaBusinessProfile?._id}
            attachedBusinessProfile={ideaBusinessProfile}
            loadingExistingProfiles={profilesQuery.isLoading}
            existingProfiles={profilesQuery.data?.profiles ?? []}
            attachBusinessProfile={(ideaBusinessProfile) => attachBusinessProfile(ideaBusinessProfile)}
            unattachBusinessProfile={unattachBusinessProfile}
          />
          {ideaBusinessProfile ? (
            canEditProfile ? (
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  alignSelf: "flex-end",
                }}
              >
                <Button
                  basic
                  onClick={() => {
                    setModalConfig({
                      openInEditMode: false,
                      defaultTab: 0,
                    });
                    setIsProfileModalOpen(true);
                  }}
                >
                  <Icon name="pencil" />
                  Manage profile
                </Button>
              </div>
            ) : null
          ) : (
            <Button
              secondary={profilesQuery.data?.profiles?.length === 0}
              basic={profilesQuery.data?.profiles?.length !== 0}
              onClick={() => (creditsafeEnabled ? setIsCreationModalOpen(true) : setIsProfileModalOpen(true))}
            >
              <Icon name="plus" />
              Create new profile
            </Button>
          )}
        </div>
      ) : null}

      {ideaBusinessProfile ? (
        <div style={{ marginTop: 16 }}>
          <Overview businessProfile={ideaBusinessProfile} />

          <Grid columns={2} stackable>
            <Grid.Column>
              {isEditing ? (
                <>
                  <h4>
                    Primary contact
                    <Icon
                      name="asterisk"
                      style={{ position: "relative", top: -5, marginLeft: 3 }}
                      color="red"
                      size="tiny"
                    />
                  </h4>
                  {ideaBusinessProfile?.contacts?.length ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 12,
                      }}
                    >
                      {primaryContact ? (
                        <>
                          {canEditProfile && isMissingRequiredContactInfo ? (
                            <Message
                              icon="exclamation triangle"
                              warning
                              size="small"
                              content={
                                <>
                                  <Message.Header>Missing primary contact information</Message.Header>
                                  <p>
                                    The following parts of your primary contact's detail sheet are missing or
                                    incomplete.
                                  </p>
                                  <ul>
                                    {requiredContactFields.map((field) => {
                                      if (field.value) {
                                        return null;
                                      }
                                      return <li key={field.label}>{field.label}</li>;
                                    })}
                                  </ul>
                                  <Button
                                    icon="pencil"
                                    basic
                                    size="small"
                                    onClick={() => {
                                      setModalConfig({
                                        openInEditMode: false,
                                        defaultTab: 1,
                                      });
                                      setIsProfileModalOpen(true);
                                    }}
                                    content="Edit contact"
                                  />
                                </>
                              }
                            />
                          ) : null}
                        </>
                      ) : (
                        <Message warning>
                          <Message.Header>No primary contact selected</Message.Header>
                          <p>
                            Choosing a primary contact is a requirement for submission, please select a contact to
                            continue.
                          </p>
                        </Message>
                      )}

                      <Dropdown
                        selection
                        clearable
                        placeholder="Select a primary contact.."
                        defaultValue={primaryContact?.id}
                        onChange={(e, { value }) => {
                          updateIdea(value, ["ideaBusinessProfileDetails", "primaryContact"]);
                        }}
                        // @ts-ignore
                        text={
                          primaryContact ? (
                            <Segment style={{ width: "100%" }}>
                              <ContactLayout contact={primaryContact} />
                            </Segment>
                          ) : null
                        }
                        options={ideaBusinessProfile?.contacts
                          ?.filter((contact) => contact.id !== primaryContact?.id)
                          ?.map((contact) => ({
                            key: contact.id,
                            value: contact.id,
                            content: (
                              <Segment>
                                <ContactLayout contact={contact} />
                              </Segment>
                            ),
                          }))}
                      />
                      {canEditProfile ? (
                        <Button
                          basic
                          style={{ alignSelf: "flex-end" }}
                          onClick={() => {
                            setIsProfileModalOpen(true);
                            setModalConfig({
                              openInEditMode: false,
                              defaultTab: 1,
                            });
                          }}
                        >
                          <Icon name="pencil" />
                          Manage contacts
                        </Button>
                      ) : null}
                    </div>
                  ) : canEditProfile ? (
                    <Message warning>
                      <Message.Header>No contacts found</Message.Header>
                      <p>
                        Choosing a primary contact is a requirement for submission, but this organisation profile has no
                        contacts set up yet. Please add at least one contact to continue.
                      </p>
                      <Button
                        basic
                        size="small"
                        onClick={() => {
                          setIsProfileModalOpen(true);
                          setModalConfig({
                            openInEditMode: false,
                            defaultTab: 1,
                          });
                        }}
                      >
                        <Icon name="pencil" />
                        Create a contact
                      </Button>
                    </Message>
                  ) : null}
                </>
              ) : null}

              {!isEditing && primaryContact ? (
                <>
                  <h4>Primary contact</h4>
                  <Segment>
                    <ContactLayout contact={primaryContact} />
                  </Segment>
                </>
              ) : null}
            </Grid.Column>
            <Grid.Column>
              {isEditing ? (
                <>
                  <h4>
                    Trading address
                    <Icon
                      name="asterisk"
                      style={{ position: "relative", top: -5, marginLeft: 3 }}
                      color="red"
                      size="tiny"
                    />
                  </h4>
                  {businessProfile?.addresses?.length || businessProfile?.address ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 12,
                      }}
                    >
                      {tradingAddress ? (
                        <>
                          {canEditProfile && isMissingRequiredAddressInfo ? (
                            <Message
                              icon="exclamation triangle"
                              warning
                              size="small"
                              content={
                                <>
                                  <Message.Header>Missing trading address information</Message.Header>
                                  <p>
                                    The following parts of your trading address detail sheet are missing or incomplete.
                                  </p>
                                  <ul>
                                    {requiredAddressFields.map((field) => {
                                      if (field.value) {
                                        return null;
                                      }
                                      return <li key={field.label}>{field.label}</li>;
                                    })}
                                  </ul>
                                  <Button
                                    icon="pencil"
                                    basic
                                    size="small"
                                    onClick={() => {
                                      setModalConfig({
                                        openInEditMode: false,
                                        defaultTab: 1,
                                      });
                                      setIsProfileModalOpen(true);
                                    }}
                                    content="Edit address"
                                  />
                                </>
                              }
                            />
                          ) : null}
                        </>
                      ) : (
                        <Message warning>
                          <Message.Header>No trading address selected</Message.Header>
                          <p>
                            Choosing a trading address is a requirement for submission, please select an address to
                            continue.
                          </p>
                        </Message>
                      )}

                      <Dropdown
                        selection
                        clearable
                        placeholder="Select a trading address.."
                        defaultValue={tradingAddress?.id}
                        onChange={(e, { value }) => {
                          updateIdea(value, ["ideaBusinessProfileDetails", "tradingAddress"]);
                        }}
                        // @ts-ignore
                        text={
                          tradingAddress ? (
                            <Segment style={{ width: "100%" }}>
                              <AddressLayout address={tradingAddress} />
                            </Segment>
                          ) : null
                        }
                        options={[
                          {
                            key: "registeredAddress",
                            value: "registeredAddress",
                            content: (
                              <Segment>
                                <AddressLayout address={address} />
                              </Segment>
                            ),
                          },
                          ...(ideaBusinessProfile?.addresses
                            ?.filter((address) => address?.id !== tradingAddress?.id)
                            ?.map((address) => ({
                              key: address?.id,
                              value: address.id,
                              content: (
                                <Segment>
                                  <AddressLayout address={address} />
                                </Segment>
                              ),
                            })) || []),
                        ]}
                      />
                      {canEditProfile ? (
                        <Button
                          basic
                          style={{ alignSelf: "flex-end" }}
                          onClick={() => {
                            setIsProfileModalOpen(true);
                            setModalConfig({
                              openInEditMode: false,
                              defaultTab: 1,
                            });
                          }}
                        >
                          <Icon name="pencil" />
                          Manage addresses
                        </Button>
                      ) : null}
                    </div>
                  ) : (
                    <Message warning>
                      <Message.Header>No addresses found</Message.Header>
                      <p>
                        Choosing a trading address is a requirement for submission, but this organisation profile has no
                        addresses set up yet. Please add at least one address to continue.
                      </p>
                      <Button
                        basic
                        size="small"
                        onClick={() => {
                          setIsProfileModalOpen(true);
                          setModalConfig({
                            openInEditMode: false,
                            defaultTab: 1,
                          });
                        }}
                      >
                        <Icon name="pencil" />
                        Create an address
                      </Button>
                    </Message>
                  )}
                </>
              ) : null}
              {!isEditing && tradingAddress ? (
                <>
                  <h4>Trading address</h4>
                  <Segment>
                    <AddressLayout address={tradingAddress} />
                  </Segment>
                </>
              ) : null}
            </Grid.Column>
          </Grid>

          <Divider hidden />
          {creditsafeCompanyId && hasReportPermissions ? (
            <div className="credit-report-area">
              {creditsafeReportUrl ? (
                <Button
                  content="Download credit report"
                  icon="file pdf"
                  as="a"
                  href={creditsafeReportUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ) : creditsafeEnabled ? (
                <Button
                  content="Download credit report"
                  icon="file pdf"
                  onClick={() => viewReportForCompany()}
                  loading={generatingCreditReport}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </IdeaSection>
  );
};

export default IdeaBusinessProfileComponent;
