import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Loader, Input, Table, Button, Icon, Dropdown, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { OpenAPI } from "simplydo/interfaces";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import styled from "styled-components";
import ConfigurableTable from "components/lib/ConfigurableTable";
import moment from "moment";
import { ImageWithFallback } from "components/lib/ImageWithFallback";
import UserAssessmentsModal from "./UserAssessmentsModal";

const AssessmentsDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  > .dashboard-title {
    display: block;
    margin: 0;
    font-weight: 600;
  }
  > table {
    min-width: 100%;
  }
`;

const AssessmentsInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  /* border: 1px solid #e9ebee; */
  cursor: pointer;
  &:hover {
    background-color: #f5f6f8;
  }
  > span {
    margin: 0;
    display: block;
    font-size: 0.9em;
  }
`;

const CompletedAssessmentsInfo = styled.div<{ $submittedAssessmentProgress?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > span {
    display: block;
    margin: 0;
    color: black;
    ${({ $submittedAssessmentProgress }) => $submittedAssessmentProgress === 100 && "color: green;"}
    ${({ $submittedAssessmentProgress }) => $submittedAssessmentProgress === 0 && "color: red;"}
  }
  > .number {
    font-size: 1.3em;
    font-weight: 600;
  }
`;

type IDashboardAssessmentIdea = OpenAPI.Schemas["Idea"];

type IDashboardAssessment = {
  assessor: string;
  ownerAssessor: OpenAPI.Schemas["User"];
  ideas: IDashboardAssessmentIdea[];
  totalSubmittedAssessments: number;
  hasSubmissionsWithConflict: boolean;
  totalStartedAssessments: number;
  totalAssessments: number;
  submittedAssessmentProgress: number;
  startedAssessmentProgress: number;
  assessorIdeaIds: string[];
};

const AssessorTable = ({
  assessors,
  setOpenAssessor,
  challenge,
  onSort,
  sort,
  unassignAssessorFromAllIdeas,
}: {
  assessors: IDashboardAssessment[];
  challenge: OpenAPI.Schemas["Challenge"];
  setOpenAssessor: (assessorId: string) => void;
  onSort: (key: string) => void;
  sort: { key: string; direction: "asc" | "desc" };
  unassignAssessorFromAllIdeas: (assessorId: string) => void;
}) => {
  const { t } = useTranslation();
  return (
    <ConfigurableTable
      tableKey="assessmentsDashboard"
      data={assessors}
      keyExtractor={(item) => item.assessor}
      onSort={onSort}
      sort={{ key: sort.key as keyof IDashboardAssessment, direction: sort.direction }}
      actions={[
        {
          name: t("assessments.dashboard.view-assessments"),
          onClick: ([assessment]) => setOpenAssessor(assessment.assessor),
          icon: "eye",
        },
        {
          name: " Unassign from all ideas",
          checkPermission: "challenge.editAssessments",
          checkPermissionScope: challenge._id,
          onClick: ([assesment]) => unassignAssessorFromAllIdeas(assesment.assessor),
          icon: "remove user",
        },
      ]}
      columns={[
        {
          key: "fullName",
          name: "Assessor",
          sortable: true,
          render: ({ item }) => (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Link to={`/users/${item.assessor}`}>
                  <ImageWithFallback
                    style={{ objectFit: "cover" }}
                    avatar
                    src={util.avatarUrl(item?.ownerAssessor)}
                    fallbackSrc={util.avatarUrl()}
                  />
                </Link>
                <Link style={{ marginLeft: 5 }} to={`/users/${item.assessor}`}>
                  {item.ownerAssessor.profile.fullName}
                </Link>
              </div>
            </>
          ),
        },
        {
          key: "totalAssessments",
          name: "Total Assessments",
          sortable: true,
          render: ({ item }) => (
            <>
              <AssessmentsInfo onClick={() => setOpenAssessor(item.assessor)}>
                <span>{item.totalAssessments}</span>
              </AssessmentsInfo>
            </>
          ),
        },
        {
          key: "notStartedAssessments",
          name: "Not Started",
          render: ({ item }) => (
            <>
              <AssessmentsInfo onClick={() => setOpenAssessor(item.assessor)}>
                <span>{item.totalAssessments - item.totalStartedAssessments - item.totalSubmittedAssessments}</span>
              </AssessmentsInfo>
            </>
          ),
        },
        {
          key: "inProgressAssessment",
          name: "In Progress",
          render: ({ item }) => (
            <>
              <AssessmentsInfo onClick={() => setOpenAssessor(item.assessor)}>
                <span>{item.totalStartedAssessments}</span>
              </AssessmentsInfo>
            </>
          ),
        },
        {
          key: "submittedAssessments",
          name: "Submitted",
          render: ({ item }) => (
            <>
              <AssessmentsInfo onClick={() => setOpenAssessor(item.assessor)}>
                <span>
                  {item.totalSubmittedAssessments} submitted{" "}
                  {item.hasSubmissionsWithConflict ? (
                    <Popup
                      trigger={<Icon size="large" color="red" name="warning sign" />}
                      content={"This assessor has reported a conflict of interest on one or more ideas."}
                    />
                  ) : null}
                </span>
              </AssessmentsInfo>
            </>
          ),
        },
        {
          key: "assessmentsCompleted",
          name: "Progress",
          sortable: true,
          render: ({ item }) => (
            <CompletedAssessmentsInfo $submittedAssessmentProgress={item.submittedAssessmentProgress}>
              <span className="number">{item.submittedAssessmentProgress}%</span>
              <span>Submitted</span>
            </CompletedAssessmentsInfo>
          ),
        },
      ]}
    />
  );
};

type IAssessmentsDashboard = {
  challenge: OpenAPI.Schemas["Challenge"];
  getAssignedAssessments: () => void;
  myAssignedAssessments: OpenAPI.GET<"/challenges/{id}/assessments">[];
  setMyAssignedAssessments: (assessments: OpenAPI.GET<"/challenges/{id}/assessments">[]) => void;
  demoingTour?: string;
};

const AssessmentsDashboard = ({ challenge }: IAssessmentsDashboard) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [assessors, setAssessors] = useState<IDashboardAssessment[]>([]);
  const [pendingInvitations, setPendingInvitations] = useState<OpenAPI.Schemas["Invitation"][]>([]);
  const [totals, setTotals] = useState<{ assessors: number; ideas: number }>({ assessors: 0, ideas: 0 });
  const [sort, setSort] = useState<"assessmentsCompleted" | "fullName" | "totalAssessments" | "">(
    "assessmentsCompleted",
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [openAssessor, setOpenAssessor] = useState<string>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  // Format: {idea_id}-{user_id}
  const [unsubmittingAssessment, setUnsubmittingAssessment] = useState<string>("");
  const [downloadingAssessmentReport, setDownloadingAssessmentReport] = useState<boolean>(false);
  const [downloadedAssessmentReport, setDownloadedAssessmentReport] = useState<string>("");

  const challengeId = challenge._id;
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const getAssessmentDashboard = useCallback(
    (shouldSetLoading) => {
      setLoading(shouldSetLoading);
      api.challenges.getAssessmentDashboard(
        challengeId,
        searchValue,
        ({ assessors: newAssessors, totals: newTotals, pendingInvitations }) => {
          setAssessors(newAssessors);
          setTotals(newTotals);
          setPendingInvitations(pendingInvitations);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          toast.error(err.message);
        },
      );
    },
    [challengeId, searchValue],
  );

  const unsubmitAssessment = useCallback(
    (ideaId, assessorId) => {
      setUnsubmittingAssessment(`${ideaId}-${assessorId}`);
      api.ideas.unsubmitAssessment(
        ideaId,
        assessorId,
        () => {
          toast.success("Unsubmitted assessment");
          setUnsubmittingAssessment("");
          getAssessmentDashboard(false);
        },
        () => {
          toast.error("Unsubmission failed");
          setUnsubmittingAssessment("");
        },
      );
    },
    [getAssessmentDashboard],
  );

  const generateAssessmentReport = useCallback(
    (withNotes: boolean) => {
      toast.success("Your report will download shortly.");
      setDownloadingAssessmentReport(true);
      api.reports.challengeAssessmentReport(
        challengeId,
        { includeNotes: withNotes },
        (c) => {
          setDownloadedAssessmentReport(c.link);
          window.location = c.link;
          setDownloadingAssessmentReport(false);
        },
        () => {
          toast.error("Error unable to generate report");
          setDownloadingAssessmentReport(false);
        },
      );
    },
    [challengeId],
  );

  useEffect(() => {
    getAssessmentDashboard(true);
  }, [getAssessmentDashboard]);

  const sortedAssessors = useMemo(() => {
    if (!sort) return assessors;
    return [...assessors].sort((a, b) => {
      if (sort === "assessmentsCompleted") {
        if (sortDirection === "asc") return a.submittedAssessmentProgress - b.submittedAssessmentProgress;
        return b.submittedAssessmentProgress - a.submittedAssessmentProgress;
      }
      if (sort === "fullName") {
        if (sortDirection === "asc")
          return (a.ownerAssessor?.profile.fullName ?? "") > (b.ownerAssessor?.profile.fullName ?? "") ? -1 : 1;
        return (a.ownerAssessor?.profile.fullName ?? "") < (b.ownerAssessor?.profile.fullName ?? "") ? -1 : 1;
      }
      if (sort === "totalAssessments") {
        if (sortDirection === "asc") return a.totalAssessments - b.totalAssessments;
        return b.totalAssessments - a.totalAssessments;
      }
      return a.submittedAssessmentProgress - b.submittedAssessmentProgress;
    });
  }, [assessors, sort, sortDirection]);

  const unassignAssessorFromAllIdeas = useCallback(
    (assessorId) => {
      const usingAssessor = assessors.find((a) => a.assessor === assessorId);
      util
        .confirm(
          `Unassign assessor from all ${t("generic.ideas")}`,
          `Are you sure you want to unassign this assessor from all ${t("generic.ideas")}? All their existing assessments will be deleted. This action cannot be undone.`,
        )
        .then(() => {
          api.challenges.unassignAssessors(
            challengeId,
            usingAssessor.assessorIdeaIds,
            [assessorId],
            () => {
              setAssessors((prevAssessors) => prevAssessors.filter((a) => a.assessor !== assessorId));
            },
            (err) => {
              toast.error(err.message);
            },
          );
        })
        .catch(() => {});
    },
    [assessors, challengeId, t],
  );

  const onSort = useCallback(
    (sortKey) => {
      if (sortKey !== sort) {
        setSort(sortKey);
        setSortDirection("desc");
      } else if (sortDirection === "desc") {
        setSortDirection("asc");
      } else {
        setSort("");
      }
    },
    [sort, sortDirection],
  );

  const resendInvitation = useCallback((invitationId) => {
    api.invitations.resend(
      invitationId,
      () => {
        toast.success("Invitation re-sent");
      },
      () => {
        toast.error("Failed to re-send invitation");
      },
    );
  }, []);

  const deleteInvitation = useCallback((invitationId) => {
    api.invitations.remove(
      invitationId,
      () => {
        toast.success("Invitation deleted");
        setPendingInvitations((prev) => prev.filter((i) => i._id !== invitationId));
      },
      () => toast.error("Unable to remove invitation"),
    );
  }, []);

  return (
    <AssessmentsDashboardContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <div>
          <Input
            style={{ maxWidth: "300px" }}
            onChange={(e, { value }) => setSearchValue(value)}
            placeholder={`Search ${util.pluralise(totals.ideas, t("generic.idea"), t("generic.ideas"))}/${util.pluralise(totals.assessors, "assessor", "assessors")}...`}
            value={searchValue}
            icon="search"
          />
          <UserAssessmentsModal
            user={user}
            assessorId={openAssessor}
            challenge={challenge}
            isOpen={!!openAssessor}
            onClose={() => setOpenAssessor("")}
            unsubmitAssessment={unsubmitAssessment}
            unsubmittingAssessment={unsubmittingAssessment}
          />
        </div>
        {downloadedAssessmentReport ? (
          <Button
            secondary
            icon="cloud download"
            content="Download assessment report"
            as="a"
            href={downloadedAssessmentReport}
            style={{ margin: 0 }}
          />
        ) : (
          <Dropdown
            trigger={
              <Button
                secondary
                icon="file excel"
                content="Generate assessment report"
                loading={downloadingAssessmentReport}
                style={{ margin: 0 }}
              />
            }
            icon={null}
          >
            <Dropdown.Menu>
              <Dropdown.Header>Generate assessment report</Dropdown.Header>
              <Dropdown.Item onClick={() => generateAssessmentReport(true)}>
                ... <b>with</b> assessor notes
              </Dropdown.Item>
              <Dropdown.Item onClick={() => generateAssessmentReport(false)}>
                ... <b>without</b> assessor notes
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <>
          <AssessorTable
            assessors={sortedAssessors}
            challenge={challenge}
            setOpenAssessor={setOpenAssessor}
            sort={{ key: sort, direction: sortDirection }}
            onSort={onSort}
            unassignAssessorFromAllIdeas={unassignAssessorFromAllIdeas}
          />
        </>
      )}
      {pendingInvitations.length > 0 && (
        <>
          <h4 style={{ marginBottom: 0 }}>Pending invitations</h4>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={1}>Email</Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>Date of last contact</Table.HeaderCell>
                <Table.HeaderCell colSpan={2}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {pendingInvitations.map((invitation) => (
                <Table.Row key={invitation._id}>
                  <Table.Cell collapsing>{invitation.invitee}</Table.Cell>
                  <Table.Cell>
                    {moment(invitation?.lastContactedAt || invitation?.createdAt).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown direction="left" trigger={<Button icon="settings cog" />} icon={null}>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            navigator.clipboard.writeText(invitation.link);
                            toast.success("Link copied");
                          }}
                        >
                          <>
                            <Icon name="copy" />
                            Copy invitation link
                          </>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            resendInvitation(invitation._id);
                          }}
                        >
                          <>
                            <Icon name="mail outline" />
                            Re-send invitation
                          </>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            deleteInvitation(invitation._id);
                          }}
                        >
                          <>
                            <Icon name="trash" />
                            Delete invitation
                          </>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
    </AssessmentsDashboardContainer>
  );
};

export default AssessmentsDashboard;
