import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Icon, List, Modal } from "semantic-ui-react";
import { PlaceholderItem, PlaceholderStack } from "components/lib/UI";
import { ThemeContext } from "styled-components";
import util from "utils/utils";
import { SideNav } from "components/lib/SideNav";
import IdeaTags from "./Tags";
import IdeaTeam, { RequestIdeaTeam } from "./Team";
import useIdeaScrollTracking, { SectionRefs } from "./useIdeaScrollTracking";
import CircularProgress from "components/lib/CircularProgress";
import { useAppSelector } from "store";
import { OpenAPI } from "simplydo/interfaces";
import QuestionList from "components/challenges/Challenge/Questions/QuestionList";

type IdeaSideBarProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"] & { ideaTemplate?: OpenAPI.Schemas["IdeaTemplate"] };
  loading: boolean;
  isIdeaPage: boolean;
  isEditing: boolean;
  sectionRefs: SectionRefs;
  updateIdea: (idea: any) => void;
  leaveIdea: () => void;
  main: any;
};

const IdeaSideBar = ({
  idea,
  loading,
  isIdeaPage,
  isEditing,
  sectionRefs,
  updateIdea,
  leaveIdea,
  main,
}: IdeaSideBarProps) => {
  const { t } = useTranslation();
  const [isShowingQuestions, setIsShowingQuestions] = useState(false);
  const user = useAppSelector((state) => state.user);

  const theme = useContext(ThemeContext);
  const { activeItemId, scrollToSection } = useIdeaScrollTracking(idea._id, sectionRefs);

  const { ideaTemplate = {} } = idea;
  const { body = [] } = ideaTemplate;

  const canEdit = util.canEditIdea(user, idea);
  const canManageChallenge = util.canManageChallenge(user, idea.ownerChallenge);

  return (
    <>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={isShowingQuestions}
        onClose={() => setIsShowingQuestions(false)}
      >
        <Modal.Header>Clarification Questions</Modal.Header>
        <Modal.Content scrolling>
          <QuestionList challenge={idea.ownerChallenge} questions={idea.ownerChallenge?.questions} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsShowingQuestions(false)}>Close</Button>
        </Modal.Actions>
      </Modal>
      <SideNav
        top={isEditing ? 155 : 105}
        navItems={
          !theme.sizes.isComputer
            ? undefined
            : [
                loading
                  ? {
                      key: "loading",
                      type: "custom",
                      content: (
                        <PlaceholderStack gap={15}>
                          <PlaceholderItem height={40} />
                          <PlaceholderItem height={40} />
                          <PlaceholderItem height={40} />
                        </PlaceholderStack>
                      ),
                    }
                  : null,
                ...body.map((section, idx) => {
                  const sectionName = section.name ?? "";
                  if (util.shouldShowIdeaSection(idea, section)) {
                    const submissibility = idea.submissibilityStatus?.detail?.[section.id];

                    const missingRequiredFields = submissibility?.incorrectFields.filter(
                      (field) => field.type === "required",
                    );
                    const wordLimitIssues = submissibility?.incorrectFields.filter(
                      (field) => field.type === "wordLimit",
                    );

                    const optionalFields = (submissibility?.visibleFields || 0) - (submissibility?.requiredFields || 0);
                    const optionalFieldsCompleted =
                      (submissibility?.completedFields || 0) - (submissibility?.completedRequiredFields || 0);
                    const remainingOptionalFields = optionalFields - optionalFieldsCompleted;
                    const totalRemaining = (submissibility?.incorrectFields?.length || 0) + remainingOptionalFields;

                    let tooltip = null;
                    if (submissibility?.incorrectFields?.length > 0) {
                      tooltip = `${submissibility.incorrectFields.length} required`;
                    }
                    if (remainingOptionalFields > 0) {
                      tooltip = tooltip
                        ? `${tooltip} (${remainingOptionalFields} optional)`
                        : `${remainingOptionalFields} optional`;
                    }
                    tooltip = tooltip
                      ? `${tooltip} ${util.pluralise(totalRemaining, "field", "fields", false)} remaining`
                      : "All fields completed!";

                    const color =
                      submissibility.completedFields === submissibility.visibleFields
                        ? "#28a745"
                        : submissibility.incorrectFields?.length > 0
                          ? "#dc3545"
                          : "#007bff";

                    return {
                      key: `${sectionName}-${idx}`,
                      content: sectionName.charAt(0).toUpperCase() + sectionName.slice(1),
                      tooltip:
                        canEdit && isEditing && submissibility && submissibility.visibleFields > 0 ? (
                          <div>
                            {tooltip}
                            {wordLimitIssues.length > 0 ? (
                              <>
                                <Divider />
                                <b>Exceeding word limit:</b>
                                <div
                                  style={{
                                    maxHeight: 300,
                                    overflow: "auto",
                                    padding: 8,
                                  }}
                                >
                                  <List bulleted>
                                    {wordLimitIssues?.map((field) => (
                                      <List.Item key={field.id}>{field.title}</List.Item>
                                    ))}
                                  </List>
                                </div>
                              </>
                            ) : null}
                            {missingRequiredFields.length > 0 ? (
                              <>
                                <Divider />
                                <b>
                                  Required {util.pluralise(missingRequiredFields.length, "field", "fields", false)}{" "}
                                  remaining:
                                </b>
                                <div
                                  style={{
                                    padding: 8,
                                    maxHeight: 300,
                                    overflow: "auto",
                                  }}
                                >
                                  <List bulleted>
                                    {missingRequiredFields?.map((field) => (
                                      <List.Item key={field.id}>
                                        {field.title}
                                        {field.description ? ` (${field.description})` : ""}
                                      </List.Item>
                                    ))}
                                  </List>
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : null,
                      iconLeft: "arrow alternate circle right",
                      rightIcon:
                        canEdit && isEditing && submissibility && submissibility.visibleFields > 0 ? (
                          <CircularProgress
                            size={16}
                            color={color}
                            progress={submissibility.completedFields / submissibility.visibleFields}
                            strokeWidth={1.5}
                            icon={<Icon name="check" style={{ color }} />}
                            iconVisible={submissibility.requiredFields === submissibility.completedRequiredFields}
                          />
                        ) : undefined,
                      active: !!(isIdeaPage && activeItemId === section.id),
                      onClick: () => scrollToSection(section.id),
                    };
                  }
                  return null;
                }),
                idea?.ownerChallenge?.impactStatements?.enabled &&
                idea?.ownerChallenge?.impactStatements?.showOnIdea &&
                (canManageChallenge ||
                  canEdit ||
                  util.hasPermission(user, "challenge.viewProjectImpacts", idea.challenge))
                  ? {
                      key: "impactStatement",
                      content: "Impact Statement",
                      iconLeft: "balance scale",
                      active: !!(isIdeaPage && activeItemId === "impactStatement"),
                      onClick: () => scrollToSection("impactStatement"),
                      tooltip:
                        canEdit &&
                        isEditing &&
                        idea.submissibilityStatus?.detail?.impactStatement?.visibleFields >
                          idea.submissibilityStatus?.detail?.impactStatement?.completedRequiredFields ? (
                          <div>
                            <b>Remaining required fields:</b>
                            <div
                              style={{
                                padding: 8,
                                maxHeight: 300,
                                overflow: "auto",
                              }}
                            >
                              <List bulleted>
                                {idea.submissibilityStatus?.detail?.impactStatement?.incorrectFields?.map((field) => (
                                  <List.Item key={field.id}>{field.title}</List.Item>
                                ))}
                              </List>
                            </div>
                          </div>
                        ) : null,
                      rightIcon:
                        canEdit &&
                        isEditing &&
                        idea.submissibilityStatus?.detail?.impactStatement &&
                        idea.submissibilityStatus?.detail?.impactStatement?.visibleFields > 0 ? (
                          <CircularProgress
                            size={16}
                            color={
                              idea.submissibilityStatus?.detail?.impactStatement?.completedRequiredFields > 0
                                ? "#28a745"
                                : idea.submissibilityStatus?.detail?.impactStatement?.incorrectFields?.length > 0
                                  ? "#dc3545"
                                  : "#007bff"
                            }
                            progress={
                              idea.submissibilityStatus?.detail?.impactStatement?.completedFields /
                              idea.submissibilityStatus?.detail?.impactStatement?.visibleFields
                            }
                            strokeWidth={1.5}
                            icon={
                              <Icon
                                name="check"
                                style={{
                                  color:
                                    idea.submissibilityStatus?.detail?.impactStatement?.completedRequiredFields > 0
                                      ? "#28a745"
                                      : idea.submissibilityStatus?.detail?.impactStatement?.incorrectFields?.length > 0
                                        ? "#dc3545"
                                        : "#007bff",
                                }}
                              />
                            }
                            iconVisible={
                              idea.submissibilityStatus?.detail?.impactStatement?.completedFields ===
                              idea.submissibilityStatus?.detail?.impactStatement?.visibleFields
                            }
                          />
                        ) : undefined,
                    }
                  : null,
                (isEditing || idea.attachments?.length > 0) &&
                !idea?.ideaTemplate?.disableAttachments &&
                !theme.sizes.isMobile
                  ? {
                      key: "attachments",
                      content: t("generic.attachments"),
                      iconLeft: "paperclip",
                      active: !!(isIdeaPage && activeItemId === "attachments"),
                      onClick: () => scrollToSection("attachments"),
                    }
                  : null,
                (!isEditing || idea.commentCount >= 1) &&
                !idea.ownerChallenge?.preventIdeaComments &&
                !theme.sizes.isMobile &&
                !loading
                  ? {
                      key: "comments",
                      content: t("generic.comments"),
                      iconLeft: "discussions",
                      active: !!(isIdeaPage && activeItemId === "comments"),
                      onClick: () => scrollToSection("comments"),
                    }
                  : null,

                idea.ownerChallenge?.questions?.length > 0 && !loading
                  ? {
                      key: "questions",
                      content: t("generic.questions"),
                      iconLeft: "talk",
                      active: !!(isIdeaPage && activeItemId === "questions"),
                      onClick: () => {
                        setIsShowingQuestions(!isShowingQuestions);
                      },
                    }
                  : null,

                user && !idea?.ownerChallenge?.ideaAnonymity && (isEditing || canManageChallenge)
                  ? {
                      key: "team",
                      type: "custom",
                      content: (
                        <>
                          <Divider />
                          <div style={{ padding: "0 12px" }}>
                            <IdeaTeam
                              updateIdea={updateIdea}
                              idea={idea}
                              isSidebar
                              canEdit={canEdit}
                              leaveIdea={leaveIdea}
                            />
                          </div>
                        </>
                      ),
                    }
                  : null,
                user && !util.canEditIdea(user, idea) && !idea?.ownerChallenge?.preventCollaboration && !loading
                  ? {
                      key: "teamJoinRequest",
                      type: "custom",
                      content: (
                        <>
                          <Divider
                            fitted
                            hidden={!idea?.ownerChallenge?.ideaAnonymity && (isEditing || canManageChallenge)}
                          />
                          <RequestIdeaTeam idea={idea} updateIdea={updateIdea} />
                        </>
                      ),
                    }
                  : null,
                user && isEditing && idea?.ownerChallenge?.tagList?.length > 0
                  ? {
                      key: "tags",
                      type: "custom",
                      content: (
                        <>
                          <Divider />
                          <div style={{ padding: "0 12px" }}>
                            <h5 style={{ color: "#333", marginBottom: 10 }}>{t("ideas.tags.title")}</h5>
                            <IdeaTags updateIdea={updateIdea} idea={idea} isSidebar />
                          </div>
                        </>
                      ),
                    }
                  : null,
              ].filter((item) => Boolean(item))
        }
        nav={
          !theme.sizes.isComputer &&
          isEditing && (
            <div style={{ padding: "0px", marginBottom: 10 }}>
              <IdeaTags updateIdea={updateIdea} idea={idea} isSidebar />
              {user && !idea?.ownerChallenge?.ideaAnonymity && (isEditing || canManageChallenge) && (
                <IdeaTeam updateIdea={updateIdea} idea={idea} isSidebar canEdit={canEdit} leaveIdea={leaveIdea} />
              )}
            </div>
          )
        }
        main={main}
      />
    </>
  );
};

export default IdeaSideBar;
