import { Dropdown, Image, Icon } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import StyledDropdownItem from "components/lib/UI/StyledDropdownItem";

const BusinessProfileDropdownItem = ({
  businessProfile,
  selected,
}: {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  selected?: boolean;
}) => {
  const { name, imageUrl, address = {}, _id } = businessProfile;
  const { country, city } = address;
  return (
    <StyledDropdownItem
      key={_id}
      text={name}
      image={
        imageUrl ? (
          <Image
            src={imageUrl}
            style={{ borderRadius: 2, overflow: "hidden", height: 28, width: 28, objectFit: "contain" }}
          />
        ) : (
          <Icon name="building" size="large" />
        )
      }
      description={city || country ? `${city || ""}${city && country ? ", " : ""}${country}` : null}
      selected={selected}
    />
  );
};

type IProfileSelector = {
  attachedBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  loadingExistingProfiles: boolean;
  existingProfiles: OpenAPI.Schemas["IdeaBusinessProfile"][];
  attachBusinessProfile: (ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
  unattachBusinessProfile: () => void;
};

const BusinessProfileSelector = ({
  attachedBusinessProfile,
  loadingExistingProfiles,
  existingProfiles,
  attachBusinessProfile,
  unattachBusinessProfile,
}: IProfileSelector) => {
  if (!existingProfiles.length) {
    return null;
  }
  return (
    <Dropdown
      selection
      fluid
      clearable
      placeholder="Select a organisation profile.."
      loading={loadingExistingProfiles}
      // @ts-ignore
      text={attachedBusinessProfile ? <BusinessProfileDropdownItem businessProfile={attachedBusinessProfile} /> : null}
      value={attachedBusinessProfile?._id}
      options={existingProfiles.map((businessProfile) => ({
        key: businessProfile._id,
        value: businessProfile._id,
        text: businessProfile.name,
        content: <BusinessProfileDropdownItem businessProfile={businessProfile} />,
      }))}
      onChange={(e, { value }) => {
        if (!value) {
          unattachBusinessProfile();
        } else {
          attachBusinessProfile(existingProfiles.find((profile) => profile._id === value));
        }
      }}
    />
  );
};

export default BusinessProfileSelector;
